<template>
  <w-navigation-drawer
    v-model="m_visible"
    class="background_project_summary_drawer"
    :mobile-breakpoint="900"
    :location="isMobile ? 'bottom' : 'right'"
  >
    <div
      class="absolute"
      style="width: inherit; height: inherit"
    >
      <div
        class="d-flex flex-column"
        style="position: fixed; width: 100%; height: 100%"
      >
        <!-- Infos projet -->
        <div class="d-none d-md-block py-3 px-6">
          <!-- Référence projet -->
          <div
            v-if="p_project.reference"
            class="align-end"
          >
            <span class="ma-0 summary-label"> RÉFÉRENCE PROJET </span>
            <span class="ma-0 ml-2 summary-value">
              {{ p_project.reference }}
            </span>
          </div>
          <!-- Patient -->
          <div class="align-end">
            <span class="ma-0 summary-label"> PATIENT </span>
            <span class="ma-0 ml-2 summary-value">
              {{ p_project.patient.lastName }}
              {{ p_project.patient.firstName }}
            </span>
          </div>
          <!-- Praticien -->
          <div
            v-if="p_privilege === Privilege.ADMIN"
            class="align-end"
          >
            <span class="ma-0 summary-label"> PRATICIEN </span>
            <span class="ma-0 ml-2 summary-value">
              Dr. {{ p_project.patient.user.lastName }}
            </span>
          </div>
        </div>

        <!-- tabs -->
        <div class="project-info-tabs-container">
          <w-tabs
            id="project-drawer-tabs"
            class="project-info-tabs"
            v-model="m_tab"
            color="transparent"
            align-tabs="center"
            hide-slider
            grow
            height="60"
          >
            <w-tab
              data-cy="tab-maxilla"
              vertical
              :disabled="!hasMaxilla"
              :value="'tab-maxilla'"
              style="height: 100%"
              :draggable="false"
              :ripple="false"
              @click="selectTab(Arcade.MAXILLA)"
            >
              <div class="d-flex flex-column ma-0">
                <div class="d-flex flex-row">
                  <img
                    class="ma-0"
                    :draggable="false"
                    :src="
                      m_tab == 'tab-maxilla'
                        ? '/img/icon/active/maxilla.svg'
                        : '/img/icon/default/maxilla.svg'
                    "
                    alt="Tab maxillaire"
                  />
                  <div class="d-flex flex-column align-center">
                    <p class="text-caption ma-0 pl-2 font-weight-bold">
                      Maxillaire
                    </p>
                    <OrderStateChip
                      v-if="hasMaxilla"
                      :p_privilege="p_privilege"
                      :p_state="p_project.orderMaxilla().status"
                      :p_isSelected="m_tab !== 'tab-maxilla'"
                      p_miniature
                    ></OrderStateChip>
                  </div>
                </div>

                <div
                  v-if="m_tab === 'tab-maxilla'"
                  class="mt-auto"
                >
                  <w-divider color="black"></w-divider>
                </div>
              </div>
            </w-tab>
            <w-tab
              data-cy="tab-mandible"
              vertical
              :disabled="!hasMandible"
              :value="'tab-mandible'"
              style="height: 100%"
              :draggable="false"
              :ripple="false"
              @click="selectTab(Arcade.MANDIBLE)"
            >
              <div class="d-flex flex-column ma-0">
                <div class="d-flex flex-row">
                  <img
                    class="ma-0"
                    :draggable="false"
                    :src="
                      m_tab == 'tab-mandible'
                        ? '/img/icon/active/mandible.svg'
                        : '/img/icon/default/mandible.svg'
                    "
                    alt="Tab mandibule"
                  />
                  <div class="d-flex flex-column align-center">
                    <p class="text-caption ma-0 pl-2 font-weight-bold">
                      Mandibule
                    </p>
                    <OrderStateChip
                      v-if="hasMandible"
                      :p_privilege="p_privilege"
                      :p_state="p_project.orderMandible().status"
                      :p_isSelected="m_tab !== 'tab-mandible'"
                      p_miniature
                    ></OrderStateChip>
                  </div>
                </div>

                <div
                  v-if="m_tab === 'tab-mandible'"
                  class="mt-auto"
                >
                  <w-divider color="black"></w-divider>
                </div>
              </div>
            </w-tab>
          </w-tabs>
        </div>

        <w-window
          v-model="m_tab"
          class="flex-grow-1 overflow-y-auto bg-white"
          style="height: 100%"
        >
          <w-window-item
            v-if="hasMaxilla"
            value="tab-maxilla"
            reverse-transition
            style="height: 100%"
          >
            <WireSummary
              data-cy="wire-summary-maxilla"
              ref="wireSummaryMaxi"
              :p_privilege="p_privilege"
              :p_order="p_project.orderMaxilla()"
              :p_project="p_project"
              :p_isEditing="true"
              :p_isInThisTab="m_tab == 'tab-maxilla'"
              @order_updated="refreshAndGoTosummary()"
              @editWire="editWire(Arcade.MAXILLA)"
              @goToSummary="$emit('goToSummary')"
              @saveManualWireEdit="$emit('saveManualWireEdit')"
              @drawOnImage="$emit('drawOnImage', Arcade.MAXILLA)"
              @hideImage="$emit('hideImages')"
              @clearImage="$emit('clearImage', Arcade.MAXILLA)"
              @sendModificationImage="
                (commentId) =>
                  $emit('sendModificationImage', commentId, Arcade.MAXILLA)
              "
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
          </w-window-item>
          <w-window-item
            v-if="hasMandible"
            value="tab-mandible"
            reverse-transition
            style="height: 100%"
          >
            <WireSummary
              data-cy="wire-summary-mandible"
              ref="wireSummaryMandi"
              :p_privilege="p_privilege"
              :p_order="p_project.orderMandible()"
              :p_project="p_project"
              :p_isEditing="true"
              :p_isInThisTab="m_tab == 'tab-mandible'"
              @order_updated="refreshAndGoTosummary()"
              @editWire="editWire(Arcade.MANDIBLE)"
              @goToSummary="$emit('goToSummary')"
              @saveManualWireEdit="$emit('saveManualWireEdit')"
              @drawOnImage="$emit('drawOnImage', Arcade.MANDIBLE)"
              @hideImage="$emit('hideImages')"
              @clearImage="$emit('clearImage', Arcade.MANDIBLE)"
              @sendModificationImage="
                (commentId) =>
                  $emit('sendModificationImage', commentId, Arcade.MANDIBLE)
              "
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
          </w-window-item>
        </w-window>

        <w-divider
          class="mx-6"
          color="black"
        ></w-divider>

        <div class="px-6 py-4 bg-white button_project_info_drawer">
          <!-- Infos projet - mobile -->
          <div class="d-block d-md-none project-info-div pa-0 pt-0">
            <div class="ma-0 pl-0">
              <p style="position: relative">
                <span class="ma-0 summary-label"> PATIENT </span>
                <span class="ma-0 ml-1 mr-0 summary-value">
                  {{ p_project.patient.lastName }}
                  {{ p_project.patient.firstName }}
                </span>
                <span
                  v-if="p_project.reference"
                  style="
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: white;
                  "
                  class="d-block d-md-none ma-0 mr-0 summary-value"
                >
                  [{{ p_project.reference }}]
                </span>
              </p>
            </div>
            <div
              v-if="p_privilege === Privilege.ADMIN"
              class="ma-0"
            >
              <p>
                <span class="ma-0 summary-label"> PRATICIEN </span>
                <span class="ma-0 ml-1 summary-value">
                  Dr. {{ p_project.patient.user.lastName }}
                </span>
              </p>
            </div>
          </div>

          <!-- Commander les fils -->
          <w-btn
            v-if="
              (p_editorState === EditorState.PROJECT_SUMMARY ||
                p_editorState === EditorState.WIRE_SUMMARY) &&
              p_project.canPlaceOrder(p_privilege)
            "
            data-cy="place-order-button"
            @click="placeOrder()"
            class="d-none d-md-block mt-2"
            block
            :ripple="false"
            :variant="
              p_project.hasToDrawWire(p_privilege) ? 'tonal' : 'elevated'
            "
            :icon-right="mdiCartOutline"
            :disabled="!p_filesLoaded"
          >
            <div>Commander les fils</div>
          </w-btn>

          <!-- Tracer le fil -->
          <w-btn
            v-if="
              p_editorState === EditorState.PROJECT_SUMMARY &&
              p_project.canDrawWire(p_privilege)
            "
            @click="$emit('editWire')"
            class="d-none d-md-block mt-2"
            block
            :ripple="false"
            :variant="
              !p_project.hasToDrawWire(p_privilege) ? 'tonal' : 'elevated'
            "
            :disabled="!p_filesLoaded"
            :icon-right="mdiRayStartEnd"
          >
            <div v-if="p_project.hasToDrawWire(p_privilege)">
              Tracer les fils
            </div>
            <div v-else>Modifier les fils</div>
          </w-btn>

          <!-- Modifier la prescription -->
          <w-btn
            v-if="
              p_editorState === EditorState.PROJECT_SUMMARY &&
              p_project.canChangePrescription(p_privilege)
            "
            @click="$emit('editPrescription')"
            class="d-none d-md-block mt-2"
            block
            variant="tonal"
            :icon-right="mdiPencil"
            :disabled="!p_filesLoaded"
          >
            <div>Modifier la prescription</div>
          </w-btn>

          <!-- Assembler -->
          <w-row
            v-if="
              p_selectedArcade !== Arcade.NONE &&
              p_editorState === EditorState.WIRE_DRAWING &&
              p_project.canDrawWire(p_privilege)
            "
          >
            <w-col class="pa-1">
              <w-select
                v-model="m_algoChoice"
                :items="getAlgoList()"
                variant="outlined"
                density="compact"
                label="Algo"
                color="white"
                class="mt-2"
                block
              >
                <div>Algo</div>
                <w-icon
                  style="position: absolute; right: 12px"
                  right
                  dark
                  :icon="mdiCog"
                >
                </w-icon>
              </w-select>
            </w-col>
            <w-col class="pa-1">
              <w-btn
                @click="assemble()"
                class="mt-2 pr-4"
                block
                :icon-right="mdiCog"
              >
                Assembler
              </w-btn>
            </w-col>
          </w-row>

          <!-- Générer le fil par IA -->
          <w-btn
            v-if="
              p_selectedArcade !== Arcade.NONE &&
              p_editorState === EditorState.WIRE_DRAWING &&
              p_project.canDrawWire(p_privilege) &&
              p_project.canGenerateWireWithAI(p_privilege, p_selectedArcade)
            "
            @click="$emit('generateWireWithAI')"
            class="d-none d-md-block mt-2"
            block
            :ripple="false"
            :disabled="!m_AIbuttonEnabled"
            :icon-right="mdiCreation"
          >
            <div>Générer le fil par IA</div>
          </w-btn>

          <!-- Placer le plan -->
          <w-btn
            v-if="
              p_selectedArcade !== Arcade.NONE &&
              p_editorState === EditorState.WIRE_SUMMARY &&
              p_project.canDrawWire(p_privilege)
            "
            @click="$emit('editPlane')"
            class="mt-2"
            block
            variant="tonal"
            :icon-right="mdiPencil"
          >
            <div v-if="m_hasAPlane">Modifier le plan</div>
            <div v-else>Ajouter un plan</div>
          </w-btn>

          <!-- Placer les points -->
          <w-btn
            v-if="
              p_selectedArcade !== Arcade.NONE &&
              p_editorState === EditorState.WIRE_SUMMARY &&
              p_project.canDrawWire(p_privilege)
            "
            @click="$emit('editWire')"
            class="mt-2"
            block
            :variant="m_wireNeedsToBeDrawn ? 'elevated' : 'tonal'"
            :icon-right="mdiPencil"
          >
            <div v-if="m_wireNeedsToBeDrawn">Dessiner le fil</div>
            <div v-else>Modifier le dessin</div>
          </w-btn>

          <!-- Modification manuelle -->
          <w-btn
            v-if="
              !m_wireNeedsToBeDrawn &&
              p_selectedArcade !== Arcade.NONE &&
              p_editorState === EditorState.WIRE_SUMMARY &&
              p_project.canManuallyEditWire(p_privilege)
            "
            @click="$emit('manualWireEdit')"
            class="mt-2"
            block
            variant="tonal"
            :icon-right="mdiCursorPointer"
          >
            Déplacer les points
          </w-btn>

          <!-- Select Maxi -->
          <w-btn
            v-if="hasMaxilla && p_editorState === EditorState.ARCADE_SELECTION"
            @click="
              $emit('selectArcade', Arcade.MAXILLA);
              $emit('editWire');
            "
            class="mt-2"
            block
            :disabled="!canDrawMaxilla"
          >
            <div>Fil maxillaire</div>
            <img
              style="position: absolute; right: 12px"
              src="/img/icon/default/maxilla-white-icon.svg"
              alt=""
            />
          </w-btn>
          <!-- Select Mandi -->
          <w-btn
            v-if="hasMandible && p_editorState === EditorState.ARCADE_SELECTION"
            @click="
              $emit('selectArcade', Arcade.MANDIBLE);
              $emit('editWire');
            "
            class="mt-2"
            block
            :disabled="!canDrawMandible"
          >
            <div>Fil mandibulaire</div>
            <img
              style="position: absolute; right: 12px"
              src="/img/icon/default/mandible-white-icon.svg"
              alt=""
            />
          </w-btn>

          <!-- Sauvegarder (Modification manuelle) -->
          <w-btn
            v-if="
              p_editorState === EditorState.WIRE_MANUAL_EDITING &&
              p_project.canManuallyEditWire(p_privilege)
            "
            @click="$emit('saveManualWireEdit')"
            class="mt-2"
            block
            :icon-right="mdiCheckboxMarkedCircle"
          >
            Valider
          </w-btn>

          <!-- Supprimer (Plan) -->
          <w-btn
            v-if="
              p_editorState === EditorState.PLANE_DRAWING &&
              p_project.canDrawPlane(p_privilege) &&
              !p_project.getWire(p_selectedArcade)!.hasToDrawPlane()
            "
            class="mt-2"
            block
            color="error"
            variant="tonal"
            :icon-right="mdiDelete"
            @click="deletePlaneDialog?.show()"
          >
            Supprimer le plan
          </w-btn>

          <BaseDialog
            ref="deletePlaneDialog"
            p_title="Supprimer le plan ?"
            p_subtitle="Voulez-vous vraiment effacer le plan ?"
            max-width="600px"
            p_noPadding
          >
            <w-card-actions class="justify-end">
              <w-btn
                @click="deletePlaneDialog?.close()"
                variant="tonal"
              >
                Non, revenir au dessin du plan
              </w-btn>
              <w-btn
                color="error"
                variant="tonal"
                @click="deletePlane()"
              >
                Oui, supprimer le plan
              </w-btn>
            </w-card-actions>
          </BaseDialog>

          <!-- Sauvegarder (Plan) -->
          <w-btn
            v-if="
              p_editorState === EditorState.PLANE_DRAWING &&
              p_selectedArcade !== Arcade.NONE &&
              p_project.canDrawPlane(p_privilege)
            "
            @click="$emit('savePlane')"
            class="mt-2"
            block
            :icon-right="mdiCheckboxMarkedCircle"
          >
            Valider le plan
          </w-btn>

          <!-- Cancel btn and dialog -->
          <w-btn
            color="error"
            class="d-none d-md-block mt-2"
            block
            variant="tonal"
            @click="cancelProjectDialog?.show()"
            v-if="
              p_editorState === EditorState.PROJECT_SUMMARY &&
              p_project.cancellable(p_privilege)
            "
            :disabled="!p_filesLoaded"
          >
            Annuler
          </w-btn>

          <BaseDialog
            ref="cancelProjectDialog"
            p_title="Annuler le projet complet ?"
            p_subtitle="Voulez-vous vraiment annuler le projet complet ?"
            max-width="600px"
            p_noPadding
          >
            <w-card-actions class="justify-end">
              <w-btn
                @click="cancelProjectDialog?.close()"
                variant="tonal"
              >
                Non, revenir au projet
              </w-btn>
              <w-btn
                color="error"
                variant="tonal"
                @click="cancelProject()"
              >
                Oui, annuler le projet
              </w-btn>
            </w-card-actions>
          </BaseDialog>
        </div>
      </div>
    </div>

    <!-- Editing selected arcade dialog -->

    <BaseDialog
      ref="selectArcadeDialog"
      max-width="900px"
      :p_title="
        'Abandonner les modifications du fil ' + m_arcadeAsString + ' ?'
      "
      :p_subtitle="'(Toute modification non sauvegardée sera perdue.)'"
    >
      <w-card-actions class="justify-end">
        <w-btn
          variant="text"
          @click="
            selectArcadeDialog?.close();
            selectTab(p_selectedArcade);
          "
        >
          Revenir à l'édition du fil {{ m_arcadeAsString }}
        </w-btn>
        <w-btn
          color="red darken-4"
          variant="text"
          @click="
            selectArcadeDialog?.close();
            $emit(
              'selectArcade',
              p_selectedArcade === Arcade.MAXILLA
                ? Arcade.MANDIBLE
                : Arcade.MAXILLA
            );
          "
        >
          Confirmer
        </w-btn>
      </w-card-actions>
    </BaseDialog>
  </w-navigation-drawer>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import WireSummary from "@/components/shared/WireSummary.vue";
import Project from "@/models/Project";
import router, { ROUTE_EDITOR } from "@/router";
import Logger from "@/shared/logger";
import { isMobile } from "@/utils/deviceUtils";
import {
  mdiCartOutline,
  mdiCheckboxMarkedCircle,
  mdiCog,
  mdiCreation,
  mdiCursorPointer,
  mdiDelete,
  mdiPencil,
  mdiPencilBox,
  mdiRayStartEnd,
} from "@mdi/js";
import {
  AlgoVersion,
  Arcade,
  ArcadeString,
  EditorState,
  Privilege,
} from "@winnove/vue-wlib/enums";
import Cookies from "js-cookie";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import OrderStateChip from "../shared/OrderStateChip.vue";

export default defineComponent({
  name: "ProjectSummaryDrawer",
  components: {
    WireSummary,
    OrderStateChip,
    BaseDialog,
  },
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_editorState: {
      type: Number as () => EditorState,
      required: true,
    },
    p_selectedArcade: {
      type: Number as () => Arcade,
      required: true,
    },
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_filesLoaded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_visible = ref(false);
    const m_tab = ref("tab-maxilla");

    const wireSummaryMaxi = ref<InstanceType<typeof WireSummary> | null>(null);
    const wireSummaryMandi = ref<InstanceType<typeof WireSummary> | null>(null);

    const m_AIbuttonEnabled = ref(true);

    const hasMaxilla = computed(() => {
      return !!props.p_project?.wireMaxilla();
    });

    const hasMandible = computed(() => {
      return !!props.p_project?.wireMandible();
    });

    const canDrawMaxilla = computed(() => {
      return props.p_project.orderMaxilla()!.canDrawWire(props.p_privilege);
    });

    const canDrawMandible = computed(() => {
      return props.p_project.orderMandible()!.canDrawWire(props.p_privilege);
    });

    const m_arcadeAsString = computed(() => {
      return ArcadeString[props.p_selectedArcade];
    });

    const m_otherArcadeAsString = computed(() => {
      return ArcadeString[
        props.p_selectedArcade === Arcade.MAXILLA
          ? Arcade.MANDIBLE
          : Arcade.MAXILLA
      ];
    });

    const m_wireNeedsToBeDrawn = computed(() => {
      return props.p_project
        .getOrder(props.p_selectedArcade)
        ?.hasToDrawWire(props.p_privilege);
    });

    const m_hasAPlane = computed(() => {
      return (
        props.p_project.getWire(props.p_selectedArcade)!.getPlanePoints()
          .length > 0
      );
    });

    const m_algoChoice = ref("Mode");

    const deletePlaneDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    const cancelProjectDialog = ref<InstanceType<typeof BaseDialog> | null>(
      null
    );

    const selectArcadeDialog = ref<InstanceType<typeof BaseDialog> | null>(
      null
    );

    function load(): void {
      router.push({
        name: ROUTE_EDITOR,
        params: { reference: props.p_project.reference },
      });
    }

    function show(): void {
      m_visible.value = true;
    }

    function hide(): void {
      m_visible.value = false;
    }

    onMounted(() => {
      if (hasMaxilla.value) {
        m_tab.value = "tab-maxilla";
      } else if (hasMandible.value) {
        m_tab.value = "tab-mandible";
      }
      // if (Cookies.get("algoChoice")) {
      //   m_algoChoice.value = Cookies.get("algoChoice")!;
      // }
    });

    watch(
      () => props.p_selectedArcade,
      () => {
        selectTab(props.p_selectedArcade);
      }
    );

    function selectTab(p_arcade: Arcade) {
      // if the user is providing design feedback
      if (
        (p_arcade !== Arcade.MANDIBLE &&
          wireSummaryMandi.value?.isUserProvidingFeedback()) ||
        (p_arcade !== Arcade.MAXILLA &&
          wireSummaryMaxi.value?.isUserProvidingFeedback())
      ) {
        logger.warning(
          "Veuillez finir la validation du fil avant de changer d'arcade."
        );
        setTimeout(() => {
          if (p_arcade === Arcade.MAXILLA) {
            m_tab.value = "tab-mandible";
          } else if (p_arcade === Arcade.MANDIBLE) {
            m_tab.value = "tab-maxilla";
          }
        }, 250);
        return; // prevent from changing tab
      }

      // if we click on the other arcade tab
      if (
        p_arcade !== props.p_selectedArcade &&
        props.p_selectedArcade !== Arcade.NONE
      ) {
        // if the user is currently modifying the wire
        if (
          props.p_project.getOrder(props.p_selectedArcade)?.canValidate() &&
          props.p_editorState === EditorState.WIRE_MANUAL_EDITING
        ) {
          logger.warning(
            "Veuillez finir de modifier le fil avant de changer d'arcade."
          );
          setTimeout(() => {
            selectTab(props.p_selectedArcade);
          }, 250);
          return; // prevent from changing tab
        }

        // else show a warning dialog
        if (props.p_editorState !== EditorState.WIRE_SUMMARY) {
          selectArcadeDialog.value?.show();
        } else {
          context.emit("selectArcade", p_arcade);
        }
      }
      if (p_arcade === Arcade.MAXILLA) {
        m_tab.value = "tab-maxilla";
      } else if (p_arcade === Arcade.MANDIBLE) {
        m_tab.value = "tab-mandible";
      }
    }

    function refreshAndGoTosummary() {
      context.emit("refresh");
      context.emit("goToSummary");
    }

    function deletePlane() {
      context.emit("deletePlane");
      deletePlaneDialog.value?.close();
    }

    function cancelProject() {
      context.emit("reject", props.p_project);
      cancelProjectDialog.value?.close();
    }

    function editWire(p_arcade: Arcade) {
      context.emit("selectArcade", p_arcade);
      context.emit("manualWireEdit");
    }

    function assemble() {
      Cookies.set("algoChoice", m_algoChoice.value);
      let selecterAlgo = AlgoVersion.BASIC;
      let algoOptions = {};

      if (m_algoChoice.value === "Gradient")
        selecterAlgo = AlgoVersion.GRADIENT;
      else if (m_algoChoice.value === "Mode") selecterAlgo = AlgoVersion.MODE;
      else if (m_algoChoice.value === "Mode Xtrm") {
        selecterAlgo = AlgoVersion.MODE;
        algoOptions = { iterations: 120000 };
      }
      context.emit("assemble", selecterAlgo, JSON.stringify(algoOptions));
    }

    function placeOrder() {
      context.emit("placeOrder");
    }

    function validateModificationRequest(p_arcade: Arcade) {
      if (p_arcade === Arcade.MAXILLA) {
        wireSummaryMaxi.value?.validateModificationRequest();
      } else if (p_arcade === Arcade.MANDIBLE) {
        wireSummaryMandi.value?.validateModificationRequest();
      } else {
        logger.error("Erreur critique : Arcade non valide");
      }
    }

    function getAlgoList() {
      switch (props.p_privilege) {
        case Privilege.ADMIN:
        case Privilege.IN_OFFICE:
          return ["Basic", "Gradient", "Mode", "Mode Xtrm"];
        case Privilege.PREMIUM:
        case Privilege.FREEMIUM:
          return ["Mode", "Mode Xtrm"];
      }
    }

    function disableAIbutton() {
      m_AIbuttonEnabled.value = false;
    }

    function enableAIbutton() {
      m_AIbuttonEnabled.value = true;
    }

    return {
      Arcade,
      m_visible,
      EditorState,
      Privilege,
      m_tab,
      hasMaxilla,
      hasMandible,
      deletePlaneDialog,
      cancelProjectDialog,
      canDrawMaxilla,
      canDrawMandible,
      selectArcadeDialog,
      m_arcadeAsString,
      m_otherArcadeAsString,
      m_wireNeedsToBeDrawn,
      m_hasAPlane,
      m_algoChoice,
      wireSummaryMaxi,
      wireSummaryMandi,
      m_AIbuttonEnabled,
      load,
      show,
      hide,
      refreshAndGoTosummary,
      cancelProject,
      deletePlane,
      selectTab,
      editWire,
      assemble,
      placeOrder,
      validateModificationRequest,
      getAlgoList,
      disableAIbutton,
      enableAIbutton,
      isMobile,
      mdiCartOutline,
      mdiRayStartEnd,
      mdiCreation,
      mdiPencil,
      mdiCog,
      mdiPencilBox,
      mdiCursorPointer,
      mdiCheckboxMarkedCircle,
      mdiDelete,
    };
  },
});
</script>

<style lang="scss" scoped>
/* Transition animation */
.slide-enter-active,
.slide-leave-active {
  transition: 0.2s;
}

.slide-enter,
.slide-leave-to {
  right: -340px;
}
</style>
