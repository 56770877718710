<template>
  <w-container
    key="ContainerEditor"
    class="pa-0"
    style="min-height: 100%"
    fluid
  >
    <div v-if="m_project">
      <AppBar
        ref="AppBar"
        :p_privilege="m_privilege"
        :p_route="p_route"
        :p_editorState="m_editorState"
      />
      <div id="sceneParentDiv">
        <div class="d-flex editor-scene-container">
          <!-- tabindex allow to use keyboard events -->
          <div
            id="sceneDiv"
            tabindex="0"
            class="scene-container"
            v-if="m_projectLoaded"
          >
            <!-- The scene itself -->
            <Scene
              ref="scene"
              :p_privilege="m_privilege"
              :p_project="m_project"
              :p_editorState="m_editorState"
              :p_selectedArcade="m_selectedArcade"
              :p_aiJsonWires="m_aiJsonWires"
              :p_query="m_query"
              :p_filesUploading="m_filesUploading"
              @save="saveProject()"
              @load-file="uploadDialog?.show()"
              @show-contact-point="showOcclusion"
              @restore-wire="restoreWire"
              @get-automatic-arch-orientation="getAutomaticArchOrientation()"
              @goBack="goBack()"
              @setWireFromAIProposal="setWireFromAIProposal"
            />
            <!-- back / quit button -->
            <w-btn
              id="btn-quit"
              @click="goBack()"
              :disabled="scene?.rotationToolActive"
            >
              <div
                v-if="
                  m_editorState === EditorState.PROJECT_SUMMARY ||
                  m_project.toBeCompleted()
                "
              >
                <span>Quitter </span>
                <w-icon :icon="mdiExitToApp"></w-icon>
              </div>
              <div v-else>
                <w-icon :icon="mdiArrowULeftTopBold"></w-icon>
              </div>
            </w-btn>
            <!-- Admin options button -->
            <w-btn
              v-if="m_privilege == Privilege.ADMIN"
              id="btn-options"
              color="white"
            >
              Options
              <OptionDrawer
                v-if="m_privilege == Privilege.ADMIN"
                ref="optionDrawer"
                @toggle-camera="scene?.toggleCamera()"
                @toggle-wireframe="scene?.toggleWireframe()"
                @toggle-keyMode="scene?.toggleKeyMode()"
                @snapshot="snapshot()"
                @export-maxi-wire="scene?.exportWireMesh(Arcade.MAXILLA)"
                @export-mandi-wire="scene?.exportWireMesh(Arcade.MANDIBLE)"
                @export-maxi-wire-together="
                  scene?.exportWireMeshAndJawTogether(Arcade.MAXILLA)
                "
                @export-mandi-wire-together="
                  scene?.exportWireMeshAndJawTogether(Arcade.MANDIBLE)
                "
              />
            </w-btn>

            <!-- occlusion legend -->
            <OcclusionLegend ref="occlusionLegend" />

            <!-- draw on image maxi -->
            <ImageDrawing ref="imageDrawingMaxi"></ImageDrawing>
            <!-- draw on image mandi -->
            <ImageDrawing ref="imageDrawingMandi"></ImageDrawing>
          </div>

          <!-- Project summary drawer -->
          <ProjectSummaryDrawer
            ref="projectSummaryDrawer"
            :p_privilege="m_privilege"
            :p_editorState="m_editorState"
            :p_selectedArcade="m_selectedArcade"
            :p_project="m_project"
            :p_filesLoaded="m_filesLoaded"
            @editPrescription="updateEditor(EditorState.PRESCRIPTION_EDIT)"
            @reject="rejectProject()"
            @goToSummary="goToSummary()"
            @assemble="assembleArcade"
            @editPlane="editPlane()"
            @editWire="editWire()"
            @deletePlane="deletePlane()"
            @savePlane="savePlane()"
            @manualWireEdit="manualWireEdit()"
            @saveManualWireEdit="saveManualWireEdit()"
            @goToDashboard="goToDashboard()"
            @refresh="refreshProject()"
            @placeOrder="placeOrder()"
            @selectArcade="selectArcade"
            @drawOnImage="drawOnImage"
            @hideImages="hideDrawingImage()"
            @clearImage="clearDrawingImage"
            @sendModificationImage="sendModificationImage"
            @generateWireWithAI="generateWireWithAI"
            @requestUpdateFromPinia="refreshProject"
          />
          <WireConfigDrawer
            ref="wireConfigDrawer"
            :p_project="m_project"
            :p_privilege="m_privilege"
            :p_wireMaxilla="m_project.wireMaxilla()"
            :p_wireMandible="m_project.wireMandible()"
            :p_filesUploading="m_filesUploading"
            :p_filesUploadError="m_filesUploadError"
            :p_uploadDataMaxi="m_uploadDataMaxi"
            :p_uploadDataMandi="m_uploadDataMandi"
            :p_rotationToolActive="scene?.rotationToolActive"
            :p_orientationCheck="m_orientationCheck"
            @retryFilesUpload="uploadFiles()"
            @save="saveProject()"
            @confirmPrescription="confirmPrescription"
            @changeFile="uploadDialog?.show()"
            @saveOrder="saveOrder"
            @placeOrder="placeOrder()"
          />
        </div>
      </div>

      <!-- Files upload dialog -->
      <UploadDialog
        ref="uploadDialog"
        :cur_project="m_project"
        @uploaded="uploaded"
      />

      <!-- assembly and AI waiting dialog -->
      <WaitingDialog
        ref="waitingDialog"
        :p_title="m_waitingDialogTitle"
        :p_progressStages="m_waitingDialogStages"
      />

      <!-- exit dialog (when project is not complete yet) -->
      <ExitDialog
        ref="exitDialog"
        :p_project="m_project"
        :p_privilege="m_privilege"
        @confirm-exit="goToDashboard"
      >
      </ExitDialog>

      <!-- Advertise dialog before leave -->
      <BaseDialog
        ref="exitWarningDialog"
        :p_title="'Attention'"
        :p_subtitle="'Vous n\'avez pas encore validé la maxillaire ou la mandibule. Êtes-vous sûr de vouloir quitter la page sans valider ?'"
      >
        <w-card-actions class="justify-end">
          <w-btn
            variant="tonal"
            @click="exitWarningDialog?.close()"
          >
            Non, je veux rester
          </w-btn>
          <w-btn
            color="error"
            variant="tonal"
            @click="leavePage"
          >
            Oui, je quitte la page
          </w-btn>
        </w-card-actions>
      </BaseDialog>
    </div>
  </w-container>
</template>

<script lang="ts">
import { Constants } from "@/Constants";
import ImageDrawing from "@/components/editor/ImageDrawing.vue";
import OcclusionLegend from "@/components/editor/OcclusionLegend.vue";
import OptionDrawer from "@/components/editor/OptionDrawer.vue";
import ProjectSummaryDrawer from "@/components/editor/ProjectSummaryDrawer.vue";
import Scene from "@/components/editor/Scene.vue";
import WireConfigDrawer from "@/components/editor/WireConfigDrawer.vue";
import ExitDialog from "@/components/editor/dialogs/ExitDialog.vue";
import UploadDialog from "@/components/editor/dialogs/UploadDialog.vue";
import WaitingDialog, {
  ProgressStage,
} from "@/components/editor/dialogs/WaitingDialog.vue";
import { LoaderBus } from "@/components/main/Loader.vue";
import AppBar from "@/components/shared/AppBar.vue";
import ArchHelper from "@/helpers/ArchHelper";
import { AuthHelper } from "@/helpers/AuthHelper";
import DateHelper from "@/helpers/DateHelper";
import OrderHelper from "@/helpers/OrderHelper";
import ProjectHelper from "@/helpers/ProjectHelper";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import WireHelper from "@/helpers/WireHelper";
import Arch, { UploadingArch } from "@/models/Arch";
import Order from "@/models/Order";
import Project from "@/models/Project";
import Wire from "@/models/Wire";
import WireHistory from "@/models/WireHistory";
import { ROUTE_ADMIN, ROUTE_PROJECTS } from "@/router";
import Logger from "@/shared/logger";
import { mdiArrowULeftTopBold, mdiExitToApp } from "@mdi/js";
import {
  AlgoVersion,
  Arcade,
  EditorState,
  OrderState,
  PointType,
  Privilege,
} from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import JSZip from "jszip";
import { useRepo } from "pinia-orm";
import { Matrix4, Quaternion, Vector3 } from "three";
import { defineComponent, nextTick, onMounted, onUnmounted, ref } from "vue";
import BaseDialog from "./BaseDialog.vue";

import CommentHelper from "@/helpers/CommentHelper";
import {
  onBeforeRouteLeave,
  RouteLocationNormalized,
  useRouter,
} from "vue-router";
export default defineComponent({
  name: "Editor",
  props: {
    p_route: {
      type: String,
      required: true,
    },
  },
  components: {
    AppBar,
    Scene,
    WireConfigDrawer,
    ProjectSummaryDrawer,
    UploadDialog,
    OptionDrawer,
    OcclusionLegend,
    ImageDrawing,
    WaitingDialog,
  },
  setup(props, { emit }) {
    const logger = Logger.getInstance();
    const uploadDialog = ref<InstanceType<typeof UploadDialog> | null>(null);
    const exitDialog = ref<InstanceType<typeof ExitDialog> | null>(null);
    const waitingDialog = ref<InstanceType<typeof WaitingDialog> | null>(null);
    const m_waitingDialogTitle = ref("");
    const m_waitingDialogStages = ref<ProgressStage[]>([]);
    const scene = ref<InstanceType<typeof Scene> | null>(null);
    const wireConfigDrawer = ref<InstanceType<typeof WireConfigDrawer> | null>(
      null
    );
    const projectSummaryDrawer = ref<InstanceType<
      typeof ProjectSummaryDrawer
    > | null>(null);
    const optionDrawer = ref<InstanceType<typeof OptionDrawer> | null>(null);
    const occlusionLegend = ref<InstanceType<typeof OcclusionLegend> | null>(
      null
    );
    const imageDrawingMaxi = ref<InstanceType<typeof ImageDrawing> | null>(
      null
    );
    const imageDrawingMandi = ref<InstanceType<typeof ImageDrawing> | null>(
      null
    );

    const m_editorState = ref(EditorState.INITIAL_STATE);
    const m_selectedArcade = ref(Arcade.NONE);
    const m_project = ref<Project | null>(null);
    const m_privilege = ref(Privilege.FREEMIUM);
    const m_filesUploading = ref(false);
    const m_filesUploadError = ref(false);
    const m_uploadDataMaxi = ref<UploadingArch>(
      new UploadingArch(Arcade.MAXILLA)
    );
    const m_uploadDataMandi = ref<UploadingArch>(
      new UploadingArch(Arcade.MANDIBLE)
    );
    const m_orientationCheck = ref(false);
    const m_aiJsonWires = ref("");
    let startTimer: Date;
    let endTimer: Date;
    const m_query = ref("");
    const m_projectLoaded = ref(false);
    const router = useRouter(); // Récupération de l'instance du routeur
    const nextRoute = ref<RouteLocationNormalized | null>(null);
    const exitWarningDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_filesLoaded = ref(false);

    const handleDialogExit = (event: BeforeUnloadEvent) => {
      event.stopPropagation();

      const { maxillaStatus, mandibleStatus } = getProjectOrdersStatus();

      if (
        m_privilege.value === Privilege.FREEMIUM ||
        m_privilege.value === Privilege.PREMIUM
      ) {
        if (
          maxillaStatus === OrderState.BEING_VALIDATED ||
          mandibleStatus === OrderState.BEING_VALIDATED
        ) {
          event.preventDefault();
          exitWarningDialog.value?.show();
        }
      }
    };

    // Function to check the project order statuses
    const getProjectOrdersStatus = () => {
      const maxillaOrder = m_project.value?.orderMaxilla();
      const mandibleOrder = m_project.value?.orderMandible();

      return {
        maxillaStatus: maxillaOrder?.status,
        mandibleStatus: mandibleOrder?.status,
      };
    };

    // Lifecycle hook to handle route changes
    onBeforeRouteLeave((to, from, next) => {
      if (
        m_privilege.value === Privilege.FREEMIUM ||
        m_privilege.value === Privilege.PREMIUM
      ) {
        nextRoute.value = to;

        if (!exitWarningDialog.value?.isOpen) {
          const { maxillaStatus, mandibleStatus } = getProjectOrdersStatus();

          if (
            maxillaStatus === OrderState.BEING_VALIDATED ||
            mandibleStatus === OrderState.BEING_VALIDATED
          ) {
            exitWarningDialog.value?.show();
            next(false);
            return;
          }
        }
      }

      next();
    });

    const leavePage = () => {
      if (nextRoute.value) {
        router.push({
          name: nextRoute.value?.name as string,
          params: nextRoute.value?.params,
        });
      }
    };

    onMounted(() => {
      if (
        m_privilege.value === Privilege.FREEMIUM ||
        m_privilege.value === Privilege.PREMIUM
      ) {
        window.addEventListener("beforeunload", handleDialogExit);
      }

      (async () => {
        try {
          m_privilege.value = AuthHelper.getLoggedUser().privilege;

          // Unload all data but keep session.
          WinnoveHelper.clearAllWithoutSession();

          // Get project reference.
          const projectReference: string = router.currentRoute.value.params
            .reference as string;

          // Get project query.
          m_query.value = router.currentRoute.value.query.json as string;

          // Open project.
          if (projectReference) {
            await _loadProject(projectReference);
            const hasRequiredArchs = m_project.value?.hasRequiredArchs();
            await _createMissingArchs();
            if (!hasRequiredArchs) {
              uploadDialog.value?.show();
            }
            m_projectLoaded.value = true;
            await nextTick();
            await _loadScanFilesFromServer();
            m_filesLoaded.value = true;
            // resfresh scene when update wire
            scene.value!.onEditorStateChange();
          } else {
            logger.error(
              "Impossible de charger le projet, référence manquante."
            );
          }
        } catch (error: any) {
          logger.error(
            error.msg,
            "Une erreur est survenue lors du chargement du projet. Veuillez réessayer."
          );
        }
      })();
    });
    onUnmounted(() => {
      if (
        m_privilege.value === Privilege.FREEMIUM ||
        m_privilege.value === Privilege.PREMIUM
      ) {
        window.removeEventListener("beforeunload", handleDialogExit);
      }
    });

    function start() {
      startTimer = new Date();
    }

    function end(): number {
      endTimer = new Date();
      return Math.abs(endTimer.getTime() - startTimer.getTime());
    }

    function updateEditor(newState: EditorState): void {
      m_editorState.value = newState;
      switch (m_editorState.value) {
        case EditorState.PROJECT_SUMMARY:
          wireConfigDrawer.value?.hide();
          projectSummaryDrawer.value?.show();
          uploadDialog.value?.hide();
          selectDefaultArcade();
          break;
        case EditorState.PRESCRIPTION_EDIT:
          wireConfigDrawer.value?.show();
          projectSummaryDrawer.value?.hide();
          uploadDialog.value?.hide();
          selectDefaultArcade();
          break;
        case EditorState.WIRE_SUMMARY:
        case EditorState.AI_WIRE_GENERATION:
          wireConfigDrawer.value?.hide();
          projectSummaryDrawer.value?.show();
          uploadDialog.value?.hide();
          break;
        case EditorState.ARCADE_SELECTION:
          if (m_selectedArcade.value === Arcade.NONE) {
            start();
            wireConfigDrawer.value?.hide();
            projectSummaryDrawer.value?.show();
            uploadDialog.value?.hide();
          } else if (
            m_project.value!.getWire(m_selectedArcade.value)!.hasToDrawPlane()
          )
            updateEditor(EditorState.PLANE_DRAWING);
          else updateEditor(EditorState.WIRE_DRAWING);

          break;
        case EditorState.PLANE_DRAWING:
        case EditorState.WIRE_DRAWING:
          start();
          wireConfigDrawer.value?.hide();
          projectSummaryDrawer.value?.show();
          uploadDialog.value?.hide();
          break;
        case EditorState.FILE_UPLOAD:
          wireConfigDrawer.value?.hide();
          projectSummaryDrawer.value?.hide();
          uploadDialog.value?.show();
          break;
      }
      scene.value?.resizeScene();
    }

    function userAskToQuit(): void {
      if (m_project.value!.anActionIsNeededFromUser(m_privilege.value)) {
        exitDialog.value?.show();
      } else {
        goToDashboard();
      }
    }

    function goBack(): void {
      scene.value!.unloadWires();
      switch (m_editorState.value) {
        case EditorState.PROJECT_SUMMARY:
          userAskToQuit();
          break;
        case EditorState.PRESCRIPTION_EDIT:
          if (m_project.value!.hasToCompletePrescription()) {
            userAskToQuit();
          } else {
            updateEditor(EditorState.PROJECT_SUMMARY);
          }
          break;
        case EditorState.WIRE_DRAWING:
          if (
            m_project
              .value!.getOrder(m_selectedArcade.value)!
              .hasToDrawWire(m_privilege.value)
          )
            if (
              m_project.value!.getWire(m_selectedArcade.value)!.hasToDrawPlane()
            )
              updateEditor(EditorState.PLANE_DRAWING);
            else updateEditor(EditorState.PROJECT_SUMMARY);
          else updateEditor(EditorState.WIRE_SUMMARY);
          break;
        case EditorState.PLANE_DRAWING:
          if (
            m_project
              .value!.getOrder(m_selectedArcade.value)!
              .hasToDrawWire(m_privilege.value)
          )
            updateEditor(EditorState.PROJECT_SUMMARY);
          else updateEditor(EditorState.WIRE_SUMMARY);
          break;
        case EditorState.WIRE_SUMMARY:
          updateEditor(EditorState.PROJECT_SUMMARY);
          break;
        case EditorState.WIRE_MANUAL_EDITING:
          updateEditor(EditorState.WIRE_SUMMARY);
          break;
        case EditorState.ARCADE_SELECTION:
          updateEditor(EditorState.PROJECT_SUMMARY);
          break;
        case EditorState.AI_WIRE_GENERATION:
          updateEditor(EditorState.WIRE_DRAWING);
          break;
      }
    }

    async function _createMissingArchs(): Promise<void> {
      if (!m_project.value!.archMaxilla()) {
        let arch: Arch = new Arch();
        arch.projectId = m_project.value!.id;
        arch.jaw = Arcade.MAXILLA;
        useRepo(Arch).save(arch);
      }
      if (!m_project.value!.archMandible()) {
        let arch: Arch = new Arch();
        arch.projectId = m_project.value!.id;
        arch.jaw = Arcade.MANDIBLE;
        useRepo(Arch).save(arch);
      }
      refreshProject();
    }

    async function uploaded(p_isOcclusion: boolean): Promise<void> {
      m_project.value!.occlusion = p_isOcclusion;
      refreshProject();
      scene.value?.placeFilesInScene(false);
      scene.value?.animateCamera();
      scene.value?.resizeScene();
      await uploadFiles();
    }

    async function uploadFiles() {
      m_filesUploading.value = true;
      m_filesUploadError.value = false;

      let fileMaxilla = uploadDialog.value?.getFileMaxilla();
      let fileMandible = uploadDialog.value?.getFileMandible();

      let archMaxilla: Arch | null = m_project.value!.archMaxilla();
      let archMandible: Arch | null = m_project.value!.archMandible();

      if (!fileMaxilla && !fileMandible) {
        m_filesUploading.value = false;
        return;
      }

      if (archMaxilla && fileMaxilla && archMandible && fileMandible) {
        const isJawInverted = scene.value?.isJawInverted();

        if (isJawInverted) {
          scene.value?.invertJawMandiMaxi();
          [fileMaxilla, fileMandible] = [fileMandible, fileMaxilla];
        }
      }

      const promises = [];

      if (archMaxilla && fileMaxilla) {
        promises.push(_addArch(archMaxilla, fileMaxilla));
      }

      if (archMandible && fileMandible) {
        promises.push(_addArch(archMandible, fileMandible));
      }

      await Promise.all(promises);

      // if an error occured during upload, we stop the process
      if (m_filesUploadError.value) {
        return;
      }

      refreshProject();
      m_filesUploading.value = false;

      if (fileMaxilla && fileMandible) {
        computeOcclusion();
      }

      await _checkArchsOrientation();
    }

    function _computeProgressData(
      p_arcade: Arcade,
      startTime: number,
      p_progressEvent: any
    ) {
      const uploadProgress = Math.round(
        (p_progressEvent.loaded * 100) / p_progressEvent.total
      );
      const timeLeft = Math.round(
        ((Date.now() - startTime) * (100 - uploadProgress)) /
          uploadProgress /
          1000
      );
      if (p_arcade === Arcade.MANDIBLE) {
        m_uploadDataMandi.value.percentageUploaded = uploadProgress;
        m_uploadDataMandi.value.remainingTime = timeLeft;
      } else {
        m_uploadDataMaxi.value.percentageUploaded = uploadProgress;
        m_uploadDataMaxi.value.remainingTime = timeLeft;
      }
    }

    function _initUploadData(p_uploadData: UploadingArch): UploadingArch {
      p_uploadData.percentageUploaded = 0;
      p_uploadData.percentageUploaded = -1;
      p_uploadData.uploadError = null;
      p_uploadData.uploading = true;
      return p_uploadData;
    }

    async function _addArch(p_arch: Arch, p_file: File) {
      // deep copy of p_file
      let file = new File([p_file], p_file.name, {
        type: p_file.type,
        lastModified: p_file.lastModified,
      });

      let formData = new FormData();
      formData.set("arch", JSON.stringify(p_arch.serialize()));

      // zip file if possible
      if (JSZip.support.blob) {
        let zip = new JSZip();
        await zip
          .file("arch.stl", file)
          .generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: { level: 1 },
          }) // we want fastest compression
          .then(function (content) {
            // update file with compressed content
            file = new File([content], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            });
          });
      } else {
        logger.error(
          "Votre navigateur n'est pas supporté, veuillez le mettre à jour ou utiliser un navigateur web plus récent (google chrome, firefox)."
        );
      }

      formData.set("model", file);

      if (p_arch.jaw === Arcade.MANDIBLE) {
        m_uploadDataMandi.value = _initUploadData(m_uploadDataMandi.value);
      } else {
        m_uploadDataMaxi.value = _initUploadData(m_uploadDataMaxi.value);
      }

      const startTime = Date.now();
      if (p_arch.existsInDatabase()) {
        await axios
          .put("archs/" + p_arch.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Content-Encoding": "deflate",
              disableLoader: true,
            },
            onUploadProgress: (p_progressEvent: any) => {
              _computeProgressData(p_arch.jaw, startTime, p_progressEvent);
            },
          })
          .catch(() => {
            m_filesUploadError.value = true;
          });
      } else {
        await axios
          .post("archs", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Content-Encoding": "deflate",
              disableLoader: true,
            },
            onUploadProgress: (p_progressEvent: any) => {
              _computeProgressData(p_arch.jaw, startTime, p_progressEvent);
            },
          })
          .then((p_response: AxiosResponse) => {
            const arch = p_response.data.arch;
            useRepo(Arch).save(arch);
            // remove initial arch
            useRepo(Arch).destroy(p_arch.id);

            p_arch = useRepo(Arch).find(arch.id)!;
          })
          .catch(() => {
            m_filesUploadError.value = true;
          });
      }
    }

    function refreshProject() {
      m_project.value = useRepo(Project).query().withAllRecursive().first();
    }

    async function _loadScanFilesFromServer() {
      const archMaxilla: Arch | null = m_project.value!.archMaxilla();
      const archMandible: Arch | null = m_project.value!.archMandible();

      // Download STL files.
      let dataMaxilla: ArrayBuffer | null = null;
      let dataMandible: ArrayBuffer | null = null;
      const downloadPromises = [];

      if (archMaxilla && archMaxilla.existsInDatabase()) {
        downloadPromises.push(
          _downloadSTL(archMaxilla).then((data) => (dataMaxilla = data))
        );
      }
      if (archMandible && archMandible.existsInDatabase()) {
        downloadPromises.push(
          _downloadSTL(archMandible).then((data) => (dataMandible = data))
        );
      }

      await Promise.all(downloadPromises);

      _filesLoaded(dataMaxilla, dataMandible);
    }

    // Load.
    async function _loadProject(p_projectReference: string): Promise<void> {
      await axios
        .get("projects/" + p_projectReference + "/summary")
        .then(async (p_response: AxiosResponse) => {
          const summary: any = p_response.data.summary;
          WinnoveHelper.clearAndInsertAllSummaryData(summary);

          const projects = useRepo(Project)
            .query()
            .where("reference", p_projectReference)
            .withAllRecursive()
            .get();

          if (projects.length == 0) {
            console.error("Project not found");
            goToDashboard();
          } else {
            m_project.value = projects[0];

            const orderMaxilla: Order | null = m_project.value.orderMaxilla();
            const orderMandible: Order | null = m_project.value.orderMandible();
            await setGeneratedWires(orderMaxilla);
            await setGeneratedWires(orderMandible);
            // save generated wires result in pinia store so that it doesn't get lost after project refresh
            if (orderMaxilla)
              useRepo(Order).where("id", orderMaxilla.id).update(orderMaxilla);
            if (orderMandible)
              useRepo(Order)
                .where("id", orderMandible.id)
                .update(orderMandible);

            if (m_project.value.hasToCompletePrescription()) {
              updateEditor(EditorState.PRESCRIPTION_EDIT);
            } else {
              updateEditor(EditorState.PROJECT_SUMMARY);
            }
          }
        })
        .catch((p_response: AxiosResponse) => {
          logger.error(
            JSON.stringify(p_response),
            "Impossible de charger le projet, veuillez réessayer."
          );
          goToDashboard();
        });
    }

    async function _downloadSTL(p_arch: Arch): Promise<ArrayBuffer | null> {
      let ret: ArrayBuffer | null = null;
      await axios
        .get("archs/" + p_arch.id + "/stl/compressed", {
          responseType: "blob",
        })
        .then(async (p_response: AxiosResponse) => {
          LoaderBus.emit("show");
          await WinnoveHelper.decompressZIP(p_response.data).then(
            (decompressRes: Error | ArrayBuffer) => {
              if (decompressRes instanceof Error) {
                throw decompressRes;
              } else {
                ret = decompressRes;
              }
            }
          );
        })
        .catch(() => {
          logger.error("Impossible de charger le fichier " + p_arch.fileName);
        });
      LoaderBus.emit("hide");
      return ret;
    }

    async function setGeneratedWires(order: Order | null) {
      if (order) {
        order.setHasGenerateWireWithAI(await hasGeneratedWires(order));
      }
    }

    async function hasGeneratedWires(p_order: Order): Promise<boolean> {
      return await axios
        .get("orders/" + p_order.id + "/hasGeneratedWires")
        .then(function (response) {
          if (response.data.fileExists) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function () {
          logger.error(
            `Error during request hasGeneratedWires for order ${p_order.id} `
          );
          return false;
        });
    }

    // Save
    async function saveProject(): Promise<void> {
      // Save project.
      saveCurrentProject();

      saveCurrentWire(Arcade.MAXILLA);
      saveCurrentOrder(Arcade.MAXILLA);
      saveCurrentArch(Arcade.MAXILLA);

      saveCurrentWire(Arcade.MANDIBLE);
      saveCurrentOrder(Arcade.MANDIBLE);
      saveCurrentArch(Arcade.MANDIBLE);

      logger.success("Projet sauvegardé");
    }

    async function saveCurrentWire(p_arcade: Arcade) {
      const wire: Wire | null = m_project.value!.getWire(p_arcade);
      if (wire) {
        wire.nbPoints = scene.value!.getPointsNb(p_arcade, PointType.BASE);
        wire.basePoints = scene.value!.getPoints(p_arcade, PointType.BASE);
        wire.planePoints = scene.value!.getPoints(p_arcade, PointType.PLANE);
        await WireHelper.updateWire(wire, AlgoVersion.DEFAULT);
      }
    }

    async function saveCurrentOrder(p_arcade: Arcade) {
      const order: Order | null = m_project.value!.getOrder(p_arcade);
      if (order) {
        await OrderHelper.updateOrder(order);
      }
    }

    async function saveCurrentArch(p_arcade: Arcade) {
      const arch: Arch | null = m_project.value!.getArch(p_arcade);
      if (arch) {
        const matrix = scene.value?.getTransform(p_arcade);
        if (matrix) {
          arch.matrix = JSON.stringify(matrix.elements);
          await ArchHelper.updateArch(arch);
        }
      }
    }

    async function saveCurrentProject() {
      if (m_project.value)
        await ProjectHelper.updateProject(m_project.value as Project);
    }

    async function confirmPrescription(
      p_wireMaxilla: Wire | null,
      p_wireMandible: Wire | null,
      p_orderMaxilla: Order | null,
      p_orderMandible: Order | null,
      p_placeOrder: boolean
    ): Promise<void> {
      // update orders
      for (const order of [p_orderMaxilla, p_orderMandible]) {
        if (order) {
          switch (m_privilege.value) {
            case Privilege.ADMIN:
            case Privilege.IN_OFFICE:
            case Privilege.FREEMIUM:
              if (order.status === OrderState.TO_BE_COMPLETED) {
                order.status = OrderState.ORDERED;
                logger.success("La commande a bien été prise en compte.");
              }
              break;
            case Privilege.PREMIUM:
              if (
                order.status === OrderState.TO_BE_COMPLETED ||
                order.status === OrderState.TO_DRAW
              ) {
                if (p_placeOrder) {
                  order.status = OrderState.ORDERED;
                  logger.success("La commande a bien été prise en compte.");
                } else order.status = OrderState.TO_DRAW;
              }
              break;
          }
        }
      }

      if (p_wireMaxilla) {
        await WireHelper.updateWire(p_wireMaxilla, AlgoVersion.DEFAULT);
      }
      if (p_orderMaxilla) {
        await OrderHelper.updateOrder(p_orderMaxilla);
      }
      if (p_wireMandible) {
        await WireHelper.updateWire(p_wireMandible, AlgoVersion.DEFAULT);
      }
      if (p_orderMandible) {
        await OrderHelper.updateOrder(p_orderMandible);
      }

      await saveCurrentArch(Arcade.MAXILLA);
      await saveCurrentArch(Arcade.MANDIBLE);

      refreshProject();

      if (p_placeOrder) updateEditor(EditorState.PROJECT_SUMMARY);
      else updateEditor(EditorState.ARCADE_SELECTION);
    }

    async function placeOrder(): Promise<void> {
      // update orders
      for (const order of m_project.value!.orders) {
        // Premium users can place orders.
        if (m_privilege.value === Privilege.PREMIUM) {
          if (order.status === OrderState.READY_TO_BE_ORDERED) {
            order.status = OrderState.TO_CONFIRM;
            await OrderHelper.updateOrder(order as Order);
          } else if (order.status === OrderState.TO_DRAW) {
            order.status = OrderState.ORDERED;
            await OrderHelper.updateOrder(order as Order);
          }
        }
        // Other users can place orders.
        else if (order.status === OrderState.READY_TO_BE_ORDERED) {
          order.status = OrderState.ORDERED;
          await OrderHelper.updateOrder(order as Order);
        }
      }

      refreshProject();
      logger.success("La commande a bien été prise en compte.");
    }

    async function assembleArcade(
      p_algo_number: number,
      p_algo_options: string
    ): Promise<void> {
      scene.value!.unloadWires();
      // Check if an arcade is selected.
      if (
        m_selectedArcade.value !== Arcade.MANDIBLE &&
        m_selectedArcade.value !== Arcade.MAXILLA
      ) {
        logger.error("Aucune arcade sélectionnée");
        return;
      }

      // get the order and wire associsted with the selected arcade
      const p_algorithm: number = p_algo_number;
      const p_order: Order = m_project.value!.getOrder(m_selectedArcade.value)!;
      const p_wire: Wire = m_project.value!.getWire(m_selectedArcade.value)!;

      let drawDuration: number = 0;
      if (startTimer) {
        drawDuration = end();
      }

      // Update point count.
      p_wire.nbPoints = scene.value!.getPointsNb(
        m_selectedArcade.value,
        PointType.BASE
      );

      // Invalid wire.
      if (!p_wire.hasEnoughPointToAssemble()) {
        logger.warning(
          "Veuillez placer au moins " +
            Constants.WIRE_MIN_POINTS +
            " points sur l'arcade " +
            (m_selectedArcade.value === Arcade.MAXILLA
              ? "maxillaire"
              : "mandibulaire") +
            " avant de lancer le pliage."
        );
        return;
      }

      // Save
      await saveProject();

      // show the WireAssembleDialog
      await openWireAssembleWaitingDialog(
        m_selectedArcade.value,
        p_algo_options
      );

      // Bend.
      await axios
        .get("projects/" + m_project.value!.id + "/bend", {
          params: {
            algo: p_algorithm,
            drawDuration,
            selectedArch: m_selectedArcade.value,
            algoOptions: p_algo_options,
          },
          timeout: 190000,
          headers: {
            disableLoader: true,
          },
        })
        .then(async (p_response: AxiosResponse) => {
          const result: any = p_response.data.bended;
          if (result.code == 0) {
            if (p_order?.isRequestingUpdate()) {
              p_order.setStatus(OrderState.UPDATE_DONE);
            } else if (m_privilege.value === Privilege.PREMIUM) {
              p_order.setStatus(OrderState.READY_TO_BE_ORDERED);
            } else {
              p_order.setStatus(OrderState.TO_CONFIRM);
            }

            if (m_selectedArcade.value === Arcade.MAXILLA) {
              p_wire.wirePoints = result.upper
                ? result.upper.wire
                : JSON.stringify([]);
            } else {
              p_wire.wirePoints = result.lower
                ? result.lower.wire
                : JSON.stringify([]);
            }
            if (p_response.data.wires_history) {
              const wiresHistory: WireHistory[] = p_response.data.wires_history;
              wiresHistory.forEach((wireHistory) => {
                (async () => {
                  const timestamp = new Date(wireHistory.timestamp);
                  wireHistory.timestamp =
                    DateHelper.formatDateInUTC(timestamp).toISOString();
                  useRepo(WireHistory).save(wireHistory);
                })();
              });
            }
            updateEditor(EditorState.WIRE_SUMMARY);
            // Save
            await OrderHelper.updateOrder(p_order);
            await WireHelper.updateWire(p_wire, p_algorithm);

            scene.value!.reloadWireHistory(p_wire.id as number);
            refreshProject();
            // Upload snapshots.
            await _uploadSnapshots();

            waitingDialog.value?.close();
            logger.success("Assemblage terminé");
          } else {
            waitingDialog.value?.closeImmediately();
            logger.error(
              result.error,
              "Une erreur est survenue lors de l'assemblage, veuillez réessayer."
            );
          }
        })
        .catch((p_error: any) => {
          waitingDialog.value?.closeImmediately();
          logger.error(
            p_error,
            "Une erreur est survenue lors de l'assemblage, veuillez réessayer."
          );
        });
    }

    async function generateWireWithAI(): Promise<void> {
      scene.value!.unloadWires();
      // Check if an arcade is selected.
      if (
        m_selectedArcade.value !== Arcade.MANDIBLE &&
        m_selectedArcade.value !== Arcade.MAXILLA
      ) {
        logger.error("Aucune arcade sélectionnée");
        return;
      }

      // show the AIAssembleDialog
      openAiWaitingDialog();
      projectSummaryDrawer.value?.disableAIbutton();

      const orderId: number = m_project.value!.getOrder(
        m_selectedArcade.value
      )!.id!;

      // Bend.
      await axios
        .get("orders/" + orderId + "/generatedWires", {
          headers: {
            disableLoader: true,
          },
        })
        .then(async (p_response: AxiosResponse) => {
          const result: any = p_response.data.generatedWires;
          if (result.code === 0) {
            waitingDialog.value?.close();
            m_aiJsonWires.value = JSON.stringify(result);
            updateEditor(EditorState.AI_WIRE_GENERATION);
            projectSummaryDrawer.value?.enableAIbutton();
            logger.success("Génération des fils terminée");
          } else {
            waitingDialog.value?.closeImmediately();
            projectSummaryDrawer.value?.enableAIbutton();
            logger.error(
              result.error,
              "Une erreur est survenue lors de l'assemblage, veuillez réessayer."
            );
          }
        })
        .catch((p_error: any) => {
          waitingDialog.value?.closeImmediately();
          projectSummaryDrawer.value?.enableAIbutton();
          logger.error(
            p_error,
            "Une erreur est survenue lors de la génération automatique du fil, veuillez réessayer."
          );
        });
    }

    async function _uploadSnapshots() {
      const wireMaxilla: Wire | null = m_project.value!.wireMaxilla();
      const wireMandible: Wire | null = m_project.value!.wireMandible();
      // Upload snapshots.
      const snapshots: [string, string] =
        scene.value!.getRendereredDataSnapshots();
      if (wireMaxilla && wireMaxilla.nbPoints > 0) {
        await _uploadSnaphot(wireMaxilla.id!, snapshots[0]);
      }
      if (wireMandible && wireMandible.nbPoints > 0) {
        await _uploadSnaphot(wireMandible.id!, snapshots[1]);
      }
    }

    async function rejectProject(): Promise<void> {
      const orderMaxilla: Order | null = m_project.value!.orderMaxilla();
      const orderMandible: Order | null = m_project.value!.orderMandible();
      if (orderMaxilla) {
        orderMaxilla.setStatus(OrderState.CANCELED);
        await OrderHelper.updateOrder(orderMaxilla);
      }
      if (orderMandible) {
        orderMandible.setStatus(OrderState.CANCELED);
        await OrderHelper.updateOrder(orderMandible);
      }

      goToDashboard();
    }

    function isJSON(str: string): boolean {
      try {
        return JSON.parse(str) && !!str;
      } catch (e) {
        return false;
      }
    }

    async function showOcclusion(p_value: boolean) {
      if (!p_value) {
        scene.value?.showOcclusion(false);
        occlusionLegend.value?.hide();
        return;
      }

      if (scene.value?.isOcclusionComputed()) {
        // Already computed.
        scene.value?.showOcclusion(p_value);
        occlusionLegend.value?.setVisible(p_value);
        return;
      }

      await saveProject();

      await axios
        .get("projects/" + m_project.value?.id + "/occlusion")
        .then((p_response: AxiosResponse) => {
          const result: any = p_response.data.occlusion;
          if (result.code == 0 && isJSON(result.occlusion)) {
            scene.value?.showOcclusion(true, result.occlusion);
            occlusionLegend.value?.show();
          } else {
            logger.error(
              result.error,
              "Une erreur est survenue lors du calcul de l'occlusion, veuillez réessayer."
            );
          }
        })
        .catch((p_error: any) => {
          logger.error(
            p_error,
            "Une erreur est survenue lors du calcul de l'occlusion, veuillez réessayer."
          );
        });
    }

    async function computeOcclusion() {
      axios.post("projects/" + m_project.value?.id + "/occlusion");
      scene.value?.resetOcclusion();
      occlusionLegend.value?.setVisible(false);
    }

    function editPlane() {
      switch (m_editorState.value) {
        case EditorState.WIRE_SUMMARY:
          updateEditor(EditorState.PLANE_DRAWING);
          break;

        default:
          if (m_project.value!.hasToDrawWire(m_privilege.value)) {
            if (m_project.value!.hasToDrawPlane())
              updateEditor(EditorState.PLANE_DRAWING);
            else updateEditor(EditorState.WIRE_DRAWING);
          } else {
            updateEditor(EditorState.WIRE_SUMMARY);
          }
          break;
      }
    }

    function editWire() {
      scene.value!.unloadWires();
      if (m_selectedArcade.value === Arcade.NONE) {
        updateEditor(EditorState.ARCADE_SELECTION);
      } else {
        switch (m_editorState.value) {
          case EditorState.WIRE_SUMMARY:
          case EditorState.PRESCRIPTION_EDIT:
            updateEditor(EditorState.WIRE_DRAWING);
            break;

          default:
            if (
              m_project
                .value!.getOrder(m_selectedArcade.value)!
                .hasToDrawWire(m_privilege.value)
            ) {
              if (
                m_project
                  .value!.getWire(m_selectedArcade.value)!
                  .hasToDrawPlane()
              )
                updateEditor(EditorState.PLANE_DRAWING);
              else updateEditor(EditorState.WIRE_DRAWING);
            } else {
              updateEditor(EditorState.WIRE_SUMMARY);
            }
            break;
        }
      }
    }

    async function saveOrder(p_order: Order) {
      await OrderHelper.updateOrder(p_order);
      refreshProject();
    }

    async function savePlane() {
      // Check if the maxilla plane is valid.
      if (
        m_selectedArcade.value === Arcade.MAXILLA &&
        m_project.value!.wireMaxilla() &&
        !m_project
          .value!.wireMaxilla()
          ?.isPlaneValid(
            scene.value!.getPointsNb(Arcade.MAXILLA, PointType.PLANE)
          )
      ) {
        logger.warning(
          "Le plan maxillaire doit être consitué de 3 points (ou de 0 si fil rond)."
        );
        return;
      }

      // Check if the mandible plane is valid.
      if (
        m_selectedArcade.value === Arcade.MANDIBLE &&
        m_project.value!.wireMandible() &&
        !m_project
          .value!.wireMandible()
          ?.isPlaneValid(
            scene.value!.getPointsNb(Arcade.MANDIBLE, PointType.PLANE)
          )
      ) {
        logger.warning(
          "Le plan mandibulaire doit être consitué de 3 points (ou de 0 si fil rond)."
        );
        return;
      }

      // Save the plane.
      if (
        scene.value!.getPointsNb(m_selectedArcade.value, PointType.BASE) === 0
      ) {
        await saveProject();
        updateEditor(EditorState.WIRE_DRAWING);
      } else if (scene.value!.snapOnPlane(m_selectedArcade.value))
        await assembleArcade(AlgoVersion.MODE, "{}"); // Assemble with algo 1, may not be the best one.
    }

    async function deletePlane() {
      if (
        scene.value!.getPointsNb(m_selectedArcade.value, PointType.PLANE) > 0
      ) {
        scene.value!.removePlane(m_selectedArcade.value);
        await saveCurrentWire(m_selectedArcade.value);
      }
      updateEditor(EditorState.WIRE_SUMMARY);
    }

    function manualWireEdit() {
      scene.value!.unloadWires();
      updateEditor(EditorState.WIRE_MANUAL_EDITING);
      start();
    }

    async function saveManualWireEdit() {
      scene.value!.unloadWires();
      const wireMaxilla = m_project.value!.wireMaxilla();
      const wireMandible = m_project.value!.wireMandible();
      const p_order: Order = m_project.value!.getOrder(m_selectedArcade.value)!;
      const drawDuration: number = end();

      if (wireMaxilla) {
        wireMaxilla.wirePoints = scene.value!.getPoints(
          Arcade.MAXILLA,
          PointType.WIRE
        );
        wireMaxilla.drawDuration = drawDuration;
        await WireHelper.updateWire(wireMaxilla, AlgoVersion.MANUAL);
        wireMaxilla.drawDuration = 0;
      }

      if (wireMandible) {
        wireMandible.wirePoints = scene.value!.getPoints(
          Arcade.MANDIBLE,
          PointType.WIRE
        );
        wireMandible.drawDuration = drawDuration;
        await WireHelper.updateWire(wireMandible, AlgoVersion.MANUAL);
        wireMandible.drawDuration = 0;
      }
      m_selectedArcade.value === Arcade.MANDIBLE
        ? scene.value!.reloadWireHistory(wireMandible?.id as number)
        : scene.value!.reloadWireHistory(wireMaxilla?.id as number);

      // if we updated a wire that required modifications
      if (p_order?.isRequestingUpdate()) {
        p_order.setStatus(OrderState.UPDATE_DONE); // set as modified
        await OrderHelper.updateOrder(p_order);
      }

      // if the doctor is done with the wire drawing, set as done and go back to summary
      if (p_order?.canValidate()) {
        p_order.setStatus(OrderState.MODIFIED_BY_DR);
        await OrderHelper.updateOrder(p_order);
        updateEditor(EditorState.PROJECT_SUMMARY);
      } else {
        updateEditor(EditorState.WIRE_SUMMARY);
      }

      await _uploadSnapshots();
    }

    async function _uploadSnaphot(
      p_wireId: number,
      p_snapshot: string
    ): Promise<void> {
      await fetch(p_snapshot)
        .then((p_res) => p_res.blob())
        .then(async (p_blob) => {
          // Create file.
          const file = new File([p_blob], "snapshot.png", {
            type: "image/png",
          });
          let formData = new FormData();
          formData.set("snapshot", file);

          // Upload.
          await axios.post("wires/" + p_wireId + "/snapshot", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        });
    }

    function _filesLoaded(
      p_dataMaxilla: ArrayBuffer | null,
      p_dataMandible: ArrayBuffer | null
    ) {
      scene.value?.loadFiles(p_dataMaxilla, p_dataMandible);
    }

    function snapshot(): void {
      saveAs(scene.value!.getRendereredData(), "snapshot.png");
    }

    function goToDashboard(): void {
      const redirect = Cookies.get("redirect-editor");
      if (redirect) {
        router.push({ path: redirect });
        Cookies.remove("redirect-editor");
      } else {
        router.push({ name: ROUTE_PROJECTS });
      }
    }

    function goToSummary(): void {
      updateEditor(EditorState.PROJECT_SUMMARY);
    }

    function selectDefaultArcade(): void {
      const wireMaxi = m_project.value!.wireMaxilla();
      const wireMandi = m_project.value!.wireMandible();

      if (wireMandi && wireMaxi) {
        m_selectedArcade.value = Arcade.NONE;
      } else if (wireMandi) {
        m_selectedArcade.value = Arcade.MANDIBLE;
      } else {
        m_selectedArcade.value = Arcade.MAXILLA;
      }
    }

    function selectArcade(p_arcade: Arcade): void {
      // check if the user can edit the wire
      if (
        !m_project.value!.getOrder(p_arcade)!.canDrawWire(m_privilege.value) &&
        !(
          m_project.value!.getOrder(p_arcade)!.canValidate() &&
          m_privilege.value === Privilege.PREMIUM
        )
      ) {
        logger.warning(
          "Il n'est plus possible de modifier le fil de cette arcade."
        );
        updateEditor(EditorState.PROJECT_SUMMARY);
        return;
      }

      // check if the wire has to be drawn and the user is in manual edit mode
      if (
        m_project.value!.getOrder(p_arcade)!.hasToDrawWire(m_privilege.value) &&
        m_editorState.value === EditorState.WIRE_MANUAL_EDITING
      ) {
        logger.warning(
          "Vous devez d'abord dessiner le fil avant de pouvoir le modifier."
        );
        updateEditor(EditorState.PROJECT_SUMMARY);
        return;
      }
      m_selectedArcade.value = p_arcade;
    }

    async function restoreWire(p_dataWires: string) {
      const jsonWire = JSON.parse(p_dataWires);
      const p_wire = m_project.value!.getWireFromId(jsonWire.wire.id);
      if (p_wire) {
        p_wire.basePoints = jsonWire.basePoints;
        p_wire.wirePoints = jsonWire.wirePoints;
        p_wire.nbPoints = JSON.parse(jsonWire.wirePoints).length;
        const p_wireHistory = useRepo(WireHistory).query().find(jsonWire.id);
        if (!p_wireHistory) {
          logger.error(
            "Impossible de retrouver le fil à restaurer, veuillez réessayer."
          );
          return;
        }

        p_wireHistory.userId = AuthHelper.getLoggedUser().id;

        p_wireHistory!.algoVersion = AlgoVersion.RESTORE;
        if (p_wireHistory) {
          await WireHelper.updateWire(
            p_wire,
            AlgoVersion.RESTORE,
            p_wireHistory
          )
            .then(() => {
              m_project.value!.orders.find(
                (order) => order.jaw === m_selectedArcade.value
              )!.wire = p_wire;
              scene.value!.reloadWireHistory(p_wire.id as number);
              scene.value!.unloadWires();
              // resfresh scene when update wire
              scene.value!.onEditorStateChange();
              logger.success("Le fil a bien été restauré.");
            })
            .catch(() => {
              logger.error(
                "Une erreur est survenue lors de la restauration du fil, veuillez réessayer."
              );
            });
        }
      }
    }

    async function setWireFromAIProposal(
      p_AIwirePoints: string,
      p_aiChoice: AlgoVersion
    ) {
      const p_wire = m_project.value!.getWire(m_selectedArcade.value)!;
      p_wire.basePoints = WireHelper.buildFakeBasePoints(p_AIwirePoints);
      p_wire.wirePoints = p_AIwirePoints;
      p_wire.nbPoints = JSON.parse(p_AIwirePoints).length;

      const newHistoryEntry: WireHistory = new WireHistory();
      newHistoryEntry.id = null;
      newHistoryEntry.userId = AuthHelper.getLoggedUser().id;
      newHistoryEntry.wireId = p_wire.id;
      newHistoryEntry.basePoints = p_wire.basePoints;
      newHistoryEntry.wirePoints = p_AIwirePoints;
      newHistoryEntry.algoVersion = p_aiChoice;
      newHistoryEntry.timer = 0;
      newHistoryEntry.timestamp = DateHelper.formatDateInUTC(
        new Date()
      ).toISOString();
      await WireHelper.updateWire(p_wire, p_aiChoice, newHistoryEntry)
        .then(() => {
          // update the wire in the project
          m_project.value!.orders.find(
            (order) => order.jaw === m_selectedArcade.value
          )!.wire = p_wire;
          // unload ai wires
          scene.value!.unloadWires();
        })
        .then(async () => {
          // change the order status
          const selectedArchOrder = m_project.value!.getOrder(
            m_selectedArcade.value
          )!;
          selectedArchOrder.status = OrderState.TO_CONFIRM;
          await OrderHelper.updateOrder(selectedArchOrder);
          // refresh project and update editor state
          refreshProject();
          updateEditor(EditorState.PROJECT_SUMMARY);
          // wait for next tick and take a snapshot
          await nextTick();
          await _uploadSnapshots();
          // show success message
          logger.success("Le fil a bien été généré.");
        });
    }

    function drawOnImage(p_arcade: Arcade) {
      let image = scene.value!.getRendereredData();
      if (p_arcade === Arcade.MANDIBLE) {
        imageDrawingMandi.value?.show(image);
      } else if (p_arcade === Arcade.MAXILLA) {
        imageDrawingMaxi.value?.show(image);
      }
    }

    function clearDrawingImage(p_arcade: Arcade) {
      if (p_arcade === Arcade.MANDIBLE) {
        imageDrawingMandi.value?.clearHistory();
      } else if (p_arcade === Arcade.MAXILLA) {
        imageDrawingMaxi.value?.clearHistory();
      }
    }

    async function sendModificationImage(
      p_commentId: number,
      p_arcade: Arcade
    ) {
      let renderedImg: File | null | undefined = null;
      if (p_arcade === Arcade.MANDIBLE) {
        if (imageDrawingMandi.value?.hasUserDrawn()) {
          renderedImg = await imageDrawingMandi.value?.renderImage();
        } else {
          logger.warning(
            "Veuillez dessiner sur l'image avant de cliquer sur envoyer."
          );
        }
      } else if (p_arcade === Arcade.MAXILLA) {
        if (imageDrawingMaxi.value?.hasUserDrawn()) {
          renderedImg = await imageDrawingMaxi.value?.renderImage();
        } else {
          logger.warning(
            "Veuillez dessiner sur l'image avant de cliquer sur envoyer."
          );
        }
      }
      if (renderedImg && p_commentId) {
        await CommentHelper.uploadCommentHasDrawnImage(
          renderedImg,
          p_commentId
        );
        projectSummaryDrawer.value!.validateModificationRequest(p_arcade);
      }
    }

    function hideDrawingImage() {
      imageDrawingMandi.value?.hide();
      imageDrawingMaxi.value?.hide();
    }

    function getQuatFromMatrix4(matrix: Matrix4): Quaternion {
      const pos: Vector3 = new Vector3();
      const quat: Quaternion = new Quaternion();
      const scale: Vector3 = new Vector3();
      matrix.decompose(pos, quat, scale);
      return quat;
    }

    // Check arch orientation and apply it if needed
    async function _checkArchsOrientation() {
      const archMandible: Arch = m_project.value!.archMandible()!;
      m_orientationCheck.value = true;
      await axios
        .get("archs/" + archMandible.id + "/orientation", {
          headers: { "Content-Type": "application/json", disableLoader: true },
        })
        .then((response: AxiosResponse) => {
          const result: any = response.data;

          if (!result.matrix) {
            logger.debug(
              "Une erreur est survenue, impossible de réorienter l'arcade. Veuillez réessayer ultérieurement."
            );
            m_orientationCheck.value = false;
            return;
          }

          const orientationMatrix: Matrix4 = new Matrix4();
          orientationMatrix.fromArray(JSON.parse(result.matrix).matrix);

          const currentMatrix: Matrix4 = scene.value!.getTransform(
            Arcade.MANDIBLE
          )!;

          // get both quaternions
          const orientationQuat = getQuatFromMatrix4(orientationMatrix);
          const currentQuat = getQuatFromMatrix4(currentMatrix);
          const resultingAngle =
            (orientationQuat.angleTo(currentQuat) * 180) / Math.PI;

          if (resultingAngle > 15) {
            logger.info(
              "L'orientation des arcades a été mise à jour automatiquement."
            );
            // reset mesh to original position
            scene.value!.applyMatrix4(
              scene.value!.getTransform(Arcade.MANDIBLE)!.clone().invert()
            );
            // apply new orientation
            scene.value!.applyMatrix4(orientationMatrix);
          }
        });
      m_orientationCheck.value = false;
    }

    // Get and apply automatic arch orientation
    async function getAutomaticArchOrientation() {
      const archMandible: Arch = m_project.value!.archMandible()!;
      await axios
        .get("archs/" + archMandible.id + "/orientation", {
          headers: { "Content-Type": "application/json" },
        })
        .then((response: AxiosResponse) => {
          const result: any = response.data;

          if (!result.matrix) {
            logger.error(
              "Une erreur est survenue, impossible de réorienter l'arcade. Veuillez réessayer ultérieurement."
            );
            return;
          }

          const orientationMatrix: Matrix4 = new Matrix4();
          orientationMatrix.fromArray(JSON.parse(result.matrix).matrix);

          // reset group mesh to original position
          scene.value!.resetGroupMeshRotation();

          // reset mesh to original position
          scene.value!.applyMatrix4(
            scene.value!.getTransform(Arcade.MANDIBLE)!.clone().invert()
          );
          // apply new orientation
          scene.value!.applyMatrix4(orientationMatrix);
        });
    }

    function openAiWaitingDialog() {
      m_waitingDialogTitle.value = "Notre IA est en train de générer vos fils";
      m_waitingDialogStages.value = [
        {
          title: "Préparation des données",
          done: false,
          inProgress: false,
          duration: 5000,
        },
        {
          title: "Exploration des solutions possibles",
          done: false,
          inProgress: false,
          duration: 15000,
        },
        {
          title: "Optimisation des solutions trouvées",
          done: false,
          inProgress: false,
          duration: 25000,
        },
        {
          title: "Génération des solutions complémentaires",
          done: false,
          inProgress: false,
          duration: 25000,
        },
        {
          title: "Réception des données",
          done: false,
          inProgress: false,
          duration: 5000,
        },
      ];
      waitingDialog.value?.show();
    }

    async function openWireAssembleWaitingDialog(
      p_arcade: Arcade,
      p_algo_options: string
    ) {
      const selectedWire: Wire = m_project.value!.getWire(p_arcade)!;
      let assemblyTimeEstimation: number = 25000;

      try {
        assemblyTimeEstimation = await ProjectHelper.getAssemblyTimeEstimation(
          selectedWire.teethRange,
          selectedWire.nbPoints,
          p_algo_options
        );
      } catch (error) {
        logger.debug(
          "Une erreur est survenue lors de l'estimation du temps d'assemblage, veuillez réessayer."
        );
      }

      m_waitingDialogTitle.value = "Optimisation de votre fil en cours";
      m_waitingDialogStages.value = [
        {
          title: "Préparation des données",
          done: false,
          inProgress: false,
          duration: 1000,
        },
        {
          title: "Optimisation des points",
          done: false,
          inProgress: false,
          duration: assemblyTimeEstimation,
        },
        {
          title: "Réception des données",
          done: false,
          inProgress: false,
          duration: 2000,
        },
      ];
      waitingDialog.value?.show();
    }

    return {
      exitWarningDialog,
      leavePage,
      uploadDialog,
      waitingDialog,
      m_waitingDialogTitle,
      m_waitingDialogStages,
      scene,
      wireConfigDrawer,
      projectSummaryDrawer,
      optionDrawer,
      occlusionLegend,
      imageDrawingMaxi,
      imageDrawingMandi,
      Privilege,
      EditorState,
      ROUTE_ADMIN,
      Arcade,
      m_project,
      m_editorState,
      m_privilege,
      m_filesUploading,
      m_filesUploadError,
      m_uploadDataMaxi,
      m_uploadDataMandi,
      m_selectedArcade,
      exitDialog,
      m_orientationCheck,
      m_aiJsonWires,
      m_query,
      m_projectLoaded,
      uploadFiles,
      saveProject,
      confirmPrescription,
      editPlane,
      editWire,
      savePlane,
      deletePlane,
      manualWireEdit,
      saveManualWireEdit,
      goToDashboard,
      goToSummary,
      snapshot,
      showOcclusion,
      computeOcclusion,
      updateEditor,
      rejectProject,
      assembleArcade,
      refreshProject,
      placeOrder,
      goBack,
      uploaded,
      saveOrder,
      selectArcade,
      restoreWire,
      drawOnImage,
      hideDrawingImage,
      clearDrawingImage,
      sendModificationImage,
      getAutomaticArchOrientation,
      generateWireWithAI,
      setWireFromAIProposal,
      mdiExitToApp,
      mdiArrowULeftTopBold,
      m_filesLoaded,
    };
  },
});
</script>

<style lang="scss" scoped>
#btn-quit {
  position: absolute;
  left: 20px;
  @media screen and (max-width: 960px) {
    top: 20px;
  }
  @media screen and (min-width: 960px) {
    bottom: 20px;
  }
}

#btn-save {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 10;
}

#btn-admin {
  position: absolute;
  bottom: 65px;
  left: 15px;
}

#btn-options {
  position: absolute;
  top: 15px;
  left: 15px;

  @media screen and (max-width: 960px) {
    right: 15px;
    left: auto;
  }
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  z-index: 10;
  margin: 0 auto; // Without this the box extends the width of the page
}
</style>
