<template>
  <BaseDialog
    data-cy="upload-dialog"
    ref="uploadDialog"
    max-width="1250px"
    p_noPadding
    :persistent="!canDismissDialog()"
  >
    <w-row class="ma-0">
      <!-- Left part -->
      <w-col class="bg-white py-8 pl-8 pr-2 justify-end d-none d-md-block">
        <div class="d-flex justify-center align-center my-8 py-8">
          <div
            style="width: 250px"
            class="mr-4"
          >
            <w-img src="/img/logo/W-Med_Bleu-nuit.png" />
          </div>
        </div>
        <div class="d-flex justify-end text-primary pr-3">
          <w-icon
            class="text-primary"
            :icon="mdiChevronRight"
          ></w-icon>
          <w-card-subtitle class="font-weight-bold text-no-wrap text-h6 pa-0"
            >Importer le maxillaire</w-card-subtitle
          >
          <img
            src="/img/icon/default/very_long_arrow.svg"
            class="ml-4 d-none d-lg-flex"
            alt="Import maxillaire"
          />
        </div>
        <w-card-text
          style="max-width: 422px"
          class="pt-2 pb-0 ml-auto pr-16 font-weight-bold"
        >
          Cliquez sur l’arcade dentaire supérieure pour importer le modèle 3D
          correspondant au maxillaire depuis votre ordinateur.
        </w-card-text>
        <div class="d-flex justify-end text-primary pr-3 mt-10">
          <w-icon
            class="text-primary"
            :icon="mdiChevronRight"
          ></w-icon>
          <w-card-subtitle class="font-weight-bold text-no-wrap text-h6 pa-0"
            >Importer la mandibule</w-card-subtitle
          >
          <img
            src="/img/icon/default/very_long_arrow.svg"
            class="ml-4 d-none d-lg-flex"
            alt="Import mandibule"
          />
        </div>
        <w-card-text
          style="max-width: 422px"
          class="pt-2 pb-0 ml-auto pr-16 font-weight-bold"
        >
          Cliquez sur l’arcade dentaire inférieure pour importer le modèle 3D
          correspondant à la mandibule depuis votre ordinateur.
        </w-card-text>
      </w-col>
      <w-btn
        v-if="!canDismissDialog()"
        :to="{ path: '/' }"
        style="
          position: absolute;
          bottom: 12px;
          left: 12px;
          padding-right: 35px;
        "
        class="d-none d-md-flex"
        :iconRight="mdiExitToApp"
      >
        Quitter
      </w-btn>
      <!-- Right part -->
      <w-col
        md="7"
        lg="7"
        class="d-flex flex-column justify-center align-center pa-8 bg-primary"
      >
        <w-form
          ref="formUpload"
          @submit.prevent="validateForm()"
          class="form_upload_dialog pb-8"
        >
          <w-card
            :variant="'outlined'"
            :color="m_maxilla.dragover ? 'white' : '#ffffff88'"
            flat
            :image="
              m_maxilla.file[0]
                ? m_maxilla.error
                  ? '/img/background/maxilla-error.svg'
                  : '/img/background/maxilla-active.svg'
                : '/img/background/maxilla.svg'
            "
            height="217px"
            width="500px"
            :class="[
              'file-card',
              'd-flex',
              'justify-center',
              'align-center',
              'mt-4',
              { 'img-opacity': !m_maxilla.file[0] },
              { 'border-dashed': !m_maxilla.file[0] },
            ]"
          >
            <div
              v-if="!m_maxilla.file[0]"
              style="
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                src="/img/icon/default/upload-file.svg"
                style="width: 40px"
                alt="Select maxillaire file"
              />
              <p class="font-weight-bold text-h6 mt-2 text-white">
                <span v-if="!m_maxilla.changeFile"
                  >Importez un fichier (maxillaire)</span
                >
                <span v-else>Remplacer le fichier (maxillaire)</span>
              </p>
            </div>
            <w-file-input
              v-model="m_maxilla.file"
              id="maxilla_input_upload_dialog"
              accept=".stl"
              :loading="!m_maxilla.loaded"
              :error="m_maxilla.error"
              density="compact"
              variant="underlined"
              color="white"
              bg-color="transparent"
              show-size
              class="mx-16 text-white"
              :style="
                !m_maxilla.file[0]
                  ? 'align-self: flex-start; display: none'
                  : 'align-self: flex-start'
              "
              @change="loadFile(m_maxilla)"
              @click:clear="resetMaxi"
            />
            <label
              data-cy="maxilla-file-input"
              v-if="!m_maxilla.file[0]"
              for="maxilla_input_upload_dialog"
              style="width: 100%; height: 100%; position: relative"
              @drop.prevent="dropFileOnMaxilla"
              @dragover.prevent="m_maxilla.dragover = true"
              @dragenter.prevent="m_maxilla.dragover = true"
              @dragleave.prevent="m_maxilla.dragover = false"
            />
          </w-card>

          <w-card
            :variant="'outlined'"
            :color="m_mandible.dragover ? 'white' : '#ffffff88'"
            flat
            :image="
              m_mandible.file[0]
                ? m_mandible.error
                  ? '/img/background/mandiba-error.svg'
                  : '/img/background/mandiba-active.svg'
                : '/img/background/mandiba.svg'
            "
            height="217px"
            width="500px"
            :class="[
              'file-card',
              'd-flex',
              'justify-center',
              'align-center',
              'mt-4',
              { 'img-opacity': !m_mandible.file[0] },
              { 'border-dashed': !m_mandible.file[0] },
            ]"
          >
            <div
              v-if="!m_mandible.file[0]"
              style="
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                src="/img/icon/default/upload-file.svg"
                style="width: 40px"
                alt="Select mandibulaire file"
              />
              <p class="font-weight-bold text-white text-h6 mt-2">
                <span v-if="!m_mandible.changeFile"
                  >Importez un fichier (mandibule)</span
                >
                <span v-else>Remplacer le fichier (mandibule)</span>
              </p>
            </div>
            <w-file-input
              v-model="m_mandible.file"
              id="mandible_input_upload_dialog"
              accept=".stl"
              :loading="!m_mandible.loaded"
              :error="m_mandible.error"
              density="compact"
              color="rgba(255,255,255,1)"
              show-size
              class="mx-16 text-white"
              variant="underlined"
              :style="
                !m_mandible.file[0]
                  ? 'align-self: flex-end; display: none'
                  : 'align-self: flex-end'
              "
              @change="loadFile(m_mandible)"
              @click:clear="resetMandi"
            />
            <label
              data-cy="mandible-file-input"
              v-if="!m_mandible.file[0]"
              for="mandible_input_upload_dialog"
              style="width: 100%; height: 100%; position: relative"
              @drop.prevent="dropFileOnMandible"
              @dragover.prevent="m_mandible.dragover = true"
              @dragenter.prevent="m_mandible.dragover = true"
              @dragleave.prevent="m_mandible.dragover = false"
            />
          </w-card>
          <w-btn
            data-cy="submit-upload-dialog"
            :disabled="!isFormValid()"
            type="submit"
            color="white"
            class="text-primary"
            style="position: absolute; bottom: 12px; right: 12px"
            >Valider
          </w-btn>
        </w-form>
      </w-col>
    </w-row>
  </BaseDialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import Project from "@/models/Project";
import Logger from "@/shared/logger";
import { mdiChevronRight, mdiExitToApp } from "@mdi/js";
import { Arcade } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { sceneEventBus } from "../Scene.vue";

interface IUploadDialogData {
  type: Arcade;
  file: File | File[];
  loadedFile: File | null;
  dragover: boolean;
  loaded: boolean;
  error: boolean;
  isOrdered: boolean;
  changeFile: boolean;
}

export default defineComponent({
  name: "UploadDialog",
  props: {
    cur_project: {
      type: Object as () => Project,
      required: true,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const formUpload = ref<HTMLFormElement | null>(null);
    const m_maxilla = reactive<IUploadDialogData>({
      type: Arcade.MAXILLA,
      file: [],
      loadedFile: null,
      dragover: false,
      loaded: false,
      error: false,
      isOrdered: true,
      changeFile: false,
    });
    const m_mandible = reactive<IUploadDialogData>({
      type: Arcade.MANDIBLE,
      file: [],
      loadedFile: null,
      dragover: false,
      loaded: false,
      error: false,
      isOrdered: true,
      changeFile: false,
    });
    const uploadDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    onMounted(() => {
      resetFiles();
      sceneEventBus.on("eventName", (evt: string) => {
        switch (evt) {
          case "mandiFileLoaded":
            m_mandible.loaded = true;
            break;
          case "mandiFileError":
            m_mandible.error = true;
            m_mandible.loaded = true;
            logger.warning(
              "Impossible de charger le fichier mandibulaire. Veuillez vérifier que le fichier ne se trouve pas dans un dossier compressé. Si le problème persiste, contactez nous..."
            );
            break;
          case "maxiFileLoaded":
            m_maxilla.loaded = true;
            break;
          case "maxiFileError":
            m_maxilla.error = true;
            m_maxilla.loaded = true;
            logger.warning(
              "Impossible de charger le fichier maxillaire. Veuillez vérifier que le fichier ne se trouve pas dans un dossier compressé. Si le problème persiste, contactez nous..."
            );
            break;
        }
      });
    });

    function isFormValid(): boolean {
      if (m_maxilla.isOrdered) {
        if (
          m_maxilla.loaded &&
          !m_maxilla.error &&
          m_mandible.loaded &&
          !m_mandible.error
        ) {
          return true;
        }
      } else if (m_mandible.loaded && !m_mandible.error) return true;
      return false;
    }

    function _dropFile(file: DragEvent, arch: IUploadDialogData) {
      arch.dragover = false;
      if (file.dataTransfer) {
        arch.file = [file.dataTransfer.files[0]];
        loadFile(arch);
      }
    }

    function dropFileOnMaxilla(file: DragEvent) {
      _dropFile(file, m_maxilla);
    }

    function dropFileOnMandible(file: DragEvent) {
      _dropFile(file, m_mandible);
    }

    function getFileMaxilla(): File | null {
      if (m_maxilla.loaded && !m_maxilla.error) return m_maxilla.loadedFile;
      return null;
    }

    function getFileMandible(): File | null {
      if (m_mandible.loaded && !m_mandible.error) return m_mandible.loadedFile;
      return null;
    }

    async function loadFile(arch: IUploadDialogData) {
      arch.loaded = false;
      arch.error = false;
      let file = Array.isArray(arch.file) ? arch.file[0] : arch.file;
      if (!Array.isArray(arch.file) && file) {
        arch.file = [file];
      }
      if (file) {
        await WinnoveHelper.readFile(file)
          .then((p_data: ArrayBuffer) => {
            // the file might have been automatically decompressed, so we want to send to our server the decompressed file
            const blob = new Blob([p_data], {
              type: "application/octet-stream",
            });
            arch.loadedFile = new File([blob], file.name);
            // load file in scene
            sceneEventBus.emit("eventData", {
              name: arch.type === Arcade.MANDIBLE ? "loadMandi" : "loadMaxi",
              data: p_data,
            });
          })
          .catch((e: any) => {
            logger.error(e.message, e.message);
            arch.loaded = true;
            arch.error = true;
          });
      } else {
        logger.warning(
          "Impossible de charger le fichier " +
            (arch.type == Arcade.MANDIBLE ? "mandibulaire" : "maxillaire") +
            ", impossible de récupérer le fichier."
        );
      }
    }

    function _confirmFile(arch: IUploadDialogData) {
      const file = Array.isArray(arch.file) ? arch.file[0] : arch.file;
      if (file) {
        const projectArch =
          arch.type === Arcade.MANDIBLE
            ? props.cur_project.archMandible()
            : props.cur_project.archMaxilla();
        if (projectArch) {
          projectArch.fileName = file.name;
        }
      } else {
        logger.warning(
          "Impossible d'ouvrir le fichier " +
            (arch.type == Arcade.MANDIBLE ? "mandibulaire" : "maxillaire") +
            ", impossible de récupérer le nom du fichier."
        );
      }
    }

    async function validateForm(): Promise<void> {
      const maxiConfirmed = m_maxilla.loaded && !m_maxilla.error;
      const mandiConfirmed = m_mandible.loaded && !m_mandible.error;
      if (maxiConfirmed) {
        _confirmFile(m_maxilla);
      }
      if (mandiConfirmed) {
        _confirmFile(m_mandible);
      }

      // test that both files are not the same
      if (mandiConfirmed && maxiConfirmed) {
        if (m_maxilla.loadedFile!.name === m_mandible.loadedFile!.name) {
          logger.warning(
            "Les fichiers maxillaire et mandibulaire ne peuvent pas être les mêmes."
          );
          return;
        }
      }

      // if both files are confirmed, we can close the dialog
      if (maxiConfirmed || mandiConfirmed) {
        hide();
        context.emit("uploaded", maxiConfirmed && mandiConfirmed);
      } else {
        logger.warning(
          "Veuillez d'abord sélectionner des fichiers avant de valider."
        );
      }
    }

    function hide() {
      uploadDialog.value?.close();
    }

    function resetFiles(p_removeFromScene: boolean = false) {
      resetMaxi(p_removeFromScene);
      resetMandi(p_removeFromScene);
    }

    function resetMaxi(p_removeFromScene: boolean = true) {
      resetArch(
        m_maxilla,
        !!props.cur_project.orderMaxilla(),
        !!props.cur_project.archMaxilla()?.existsInDatabase(),
        p_removeFromScene
      );
    }

    function resetMandi(p_removeFromScene: boolean = true) {
      resetArch(
        m_mandible,
        !!props.cur_project.orderMandible(),
        !!props.cur_project.archMandible()?.existsInDatabase(),
        p_removeFromScene
      );
    }

    function resetArch(
      arch: IUploadDialogData,
      isOrdered: boolean,
      changeFile: boolean,
      p_removeFromScene: boolean
    ) {
      arch.file = [];
      arch.loadedFile = null;
      arch.loaded = false;
      arch.error = false;
      arch.isOrdered = isOrdered;
      arch.changeFile = changeFile;
      if (p_removeFromScene)
        sceneEventBus.emit(
          "eventName",
          arch.type === Arcade.MANDIBLE ? "deleteMandi" : "deleteMaxi"
        );
    }

    function show() {
      resetFiles();
      uploadDialog.value?.show();
    }

    function canDismissDialog(): boolean {
      return (
        (m_maxilla.changeFile || m_mandible.changeFile) &&
        !m_mandible.loadedFile &&
        !m_maxilla.loadedFile
      );
    }

    return {
      uploadDialog,
      formUpload,
      m_maxilla,
      m_mandible,
      validateForm,
      hide,
      show,
      getFileMandible,
      getFileMaxilla,
      dropFileOnMaxilla,
      dropFileOnMandible,
      isFormValid,
      loadFile,
      canDismissDialog,
      resetMaxi,
      resetMandi,
      mdiChevronRight,
      mdiExitToApp,
    };
  },
  components: { BaseDialog },
});
</script>

<style lang="scss" scoped>
.form_upload_dialog label {
  cursor: pointer;
}
.arrow_icon_upload_dialog {
  transform: rotate(90deg);
}
.maxilaire_icon_upload_dialog {
  transform: rotate(180deg);
}
.background_maxilla_upload_dialog {
  background: url("/img/background/maxilla.png") no-repeat center center;
  background-size: cover;
  width: 500px;
  height: 217px;
}

.background_mandible_upload_dialog {
  background: url("/img/background/mandiba.png") no-repeat center center;
  background-size: cover;
  width: 500px;
  height: 217px;
}

.w-file-input :deep(.v-field__overlay) {
  opacity: 0;
}

.file-card {
  position: relative;
}

.border-dashed {
  border: thin dashed;
}

.img-opacity {
  :deep(.v-card__image) {
    opacity: 0.2;
  }
}
</style>
