<template>
  <w-card
    class="pa-0 pt-0 ma-4 reduce-br"
    elevation="2"
  >
    <w-card-title
      class="d-flex align-center font-weight-bold pa-2 project-info-tabs bg-primary text-white"
    >
      <img
        v-if="p_arcade === Arcade.MANDIBLE"
        class="ma-0"
        :src="'/img/icon/default/mandible.svg'"
        alt="Mandible"
      />
      <img
        v-if="p_arcade === Arcade.MAXILLA"
        class="ma-0"
        :src="'/img/icon/default/maxilla.svg'"
        alt="Maxillaire"
      />
      <p class="ma-0 pl-2 font-weight-bold wire-title">
        Fil {{ m_wireTypeString }}
      </p>
    </w-card-title>

    <!-- show wire config -->
    <w-card-text
      v-if="!p_isCancelled"
      class="px-4 pb-2 pt-2 bg-white"
    >
      <label
        for="wire-material-select"
        class="font-weight-bold text-subtitle-1"
        >Matériau</label
      >
      <w-select
        data-cy="wire-material-select"
        id="wire-material-select"
        v-model="m_selectedMaterial"
        :items="m_materials"
        item-title="text"
        item-value="value"
        class="mb-2 custom-selector"
        hide-details
      ></w-select>

      <label
        class="font-weight-bold text-subtitle-1 mb-3"
        for="wire-teeth-select"
        style="display: block"
        >Numérotation</label
      >
      <TeethSelect
        id="wire-teeth-select"
        class="mb-2"
        v-model:p_teeth="m_wire.teethRange"
        :p_arcade="p_arcade"
      />

      <w-switch
        v-if="p_privilege !== Privilege.IN_OFFICE"
        :modelValue="!!m_wire.keyNeeded"
        @update:model-value="m_wire.keyNeeded = $event"
        color="primary"
        class="font-weight-bold text-subtitle-1 ml-1 mt-0"
        label="Clé de positionnement"
        hide-details
        density="compact"
      ></w-switch>

      <w-switch
        v-if="p_privilege !== Privilege.IN_OFFICE"
        :modelValue="!!m_order.validationNeed"
        @update:model-value="m_order.validationNeed = $event"
        color="primary"
        class="font-weight-bold text-subtitle-1 ml-1 mt-0"
        label="Demande de validation du dessin"
        hide-details
        density="compact"
      ></w-switch>
    </w-card-text>

    <!-- Wire is cancelled -->
    <w-card-text
      v-else
      class="px-4 pb-0 pt-2 bg-white"
    >
      <span class="font-weight-bold px-0 pt-0 pb-0 text-subtitle-1">
        Ce fil a été annulé.
      </span>
    </w-card-text>

    <w-card-actions
      v-if="!p_isCancelled"
      class="justify-end"
    >
      <!-- Cancel button -->
      <w-btn
        color="white"
        variant="tonal"
        :disabled="p_rotationToolActive"
        @click="cancelDialog?.show()"
      >
        Annuler
      </w-btn>

      <!-- Change file button -->
      <w-btn
        variant="flat"
        color="white"
        @click="$emit('change-file')"
        :disabled="p_rotationToolActive || p_filesUploading"
      >
        Changer le fichier
      </w-btn>
    </w-card-actions>

    <!-- Cancel dialog -->
    <BaseDialog
      ref="cancelDialog"
      max-width="600px"
      p_noPadding
      :p_title="'Annuler le fil ' + m_wireTypeString + ' ?'"
      :p_subtitle="
        'Voulez-vous vraiment annuler le fil ' + m_wireTypeString + ' ?'
      "
    >
      <!-- Cancel dialog content -->
      <w-card-actions class="justify-end">
        <w-btn
          @click="cancelDialog?.close()"
          variant="tonal"
        >
          Non, je veux garder le fil
        </w-btn>
        <w-btn
          color="error"
          variant="flat"
          @click="cancelWire()"
        >
          Oui, annuler le fil
        </w-btn>
      </w-card-actions>
    </BaseDialog>
  </w-card>
</template>

<script lang="ts">
import TeethSelect from "@/components/editor/shared/TeethSelect.vue";
import BaseDialog from "@/components/shared/BaseDialog.vue";
import { EnumSelectEntry } from "@/helpers/EnumHelper";
import Config, { OutOfStockMaterial } from "@/models/Config";
import Order from "@/models/Order";
import Wire from "@/models/Wire";
import {
  Arcade,
  Dimension,
  DimensionsByMaterials,
  DimensionString,
  Material,
  MaterialString,
  Privilege,
  WireTypeByDimensions,
} from "@winnove/vue-wlib/enums";
import Cookies from "js-cookie";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  WritableComputedRef,
} from "vue";

export default defineComponent({
  name: "WireConfigDrawer",
  components: {
    TeethSelect,
    BaseDialog,
  },
  props: {
    p_wire: {
      // This was a propSync, check if it's still working
      type: Object as () => Wire,
      required: true,
    },
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_arcade: {
      type: Number as () => Arcade,
      required: true,
    },
    p_isCancelled: {
      type: Boolean,
      required: true,
    },
    p_rotationToolActive: {
      type: Boolean,
      required: false,
    },
    p_filesUploading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const m_materials = ref<EnumSelectEntry[]>([]);
    const m_wire: WritableComputedRef<Wire> = computed({
      get: () => props.p_wire,
      set: (p_wire: Wire) => {
        context.emit("update:p_wire", p_wire);
      },
    });
    const m_order: WritableComputedRef<Order> = computed({
      get: () => props.p_order,
      set: (p_order: Order) => {
        context.emit("update:p_order", p_order);
      },
    });

    const m_selectedMaterial = computed({
      get: () => {
        return m_wire.value.material * 100 + m_wire.value.dimension;
      },
      set: (material: number) => {
        m_wire.value.material = Math.floor(material / 100);
        m_wire.value.dimension = material % 100;
        m_wire.value.wireType = WireTypeByDimensions[m_wire.value.dimension];
      },
    });
    const m_wireTypeString = computed(() => {
      if (props.p_arcade === Arcade.MANDIBLE) {
        return "Mandibulaire";
      } else if (props.p_arcade === Arcade.MAXILLA) {
        return "Maxillaire";
      } else {
        return "";
      }
    });
    const cancelDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    function _refreshMaterialSelect(): void {
      // loop through DimensionsByMaterials and add each dimension to the material
      const dimByMat = JSON.parse(JSON.stringify(DimensionsByMaterials));
      if (props.p_privilege === Privilege.ADMIN) {
        // Add material to the enum once it has been released
        dimByMat[Material.TWISTED_STEEL].push(Dimension._0170);
      } else if (props.p_privilege === Privilege.IN_OFFICE) {
        dimByMat[Material.TWISTED_STEEL].push(Dimension._0170);
      }

      // fetch the out of stock materials
      let outOfStockMaterials: OutOfStockMaterial[] = [];
      if (props.p_privilege !== Privilege.IN_OFFICE) {
        outOfStockMaterials = Config.getOutOfStockMaterials();
      }

      for (let material = 0; material < dimByMat.length; material++) {
        for (const element of dimByMat[material]) {
          const dimension = element;
          const isOutOfStock = outOfStockMaterials.some(
            (item: { material: number; dimension: number }) =>
              item.material === material && item.dimension === dimension
          );
          m_materials.value.push({
            text:
              MaterialString[material] +
              " " +
              DimensionString[dimension] +
              (isOutOfStock ? " (Indisponible)" : ""),
            value: material * 100 + dimension,
          });
        }
      }
    }

    function cancelWire(): void {
      cancelDialog.value?.close();
      m_order.value.cancel();
      context.emit("save-order", m_order.value);
    }

    onMounted(() => {
      _refreshMaterialSelect();
      // if prescriptionPreferencesMaxilla cookie is set, pre-fill the wire attributes
      if (
        props.p_order.hasToCompletePrescription() &&
        Cookies.get(
          props.p_arcade === Arcade.MANDIBLE
            ? "prescriptionPreferencesMandible"
            : "prescriptionPreferencesMaxilla"
        )
      ) {
        const preferences = JSON.parse(
          Cookies.get(
            props.p_arcade === Arcade.MANDIBLE
              ? "prescriptionPreferencesMandible"
              : "prescriptionPreferencesMaxilla"
          )!
        );
        m_wire.value.material = preferences.material;
        m_wire.value.dimension = preferences.dimension;
        m_wire.value.wireType = preferences.wireType;
        m_wire.value.teethRange = preferences.teethRange;
        m_wire.value.keyNeeded = preferences.keyNeeded;
      }
    });

    function checkIfPrescriptionIsAsSavedPreferences(): boolean {
      if (
        props.p_order.hasToCompletePrescription() &&
        Cookies.get(
          props.p_arcade === Arcade.MANDIBLE
            ? "prescriptionPreferencesMandible"
            : "prescriptionPreferencesMaxilla"
        )
      ) {
        const preferences = JSON.parse(
          Cookies.get(
            props.p_arcade === Arcade.MANDIBLE
              ? "prescriptionPreferencesMandible"
              : "prescriptionPreferencesMaxilla"
          )!
        );

        return (
          m_wire.value.material === preferences.material &&
          m_wire.value.dimension === preferences.dimension &&
          m_wire.value.wireType === preferences.wireType &&
          m_wire.value.teethRange === preferences.teethRange &&
          !!m_wire.value.keyNeeded === !!preferences.keyNeeded
        );
      }
      return false;
    }

    watch(m_wire.value, () => {
      context.emit(
        "prescriptionAsDefault",
        checkIfPrescriptionIsAsSavedPreferences()
      );
    });

    return {
      MaterialString: MaterialString,
      DimensionString: DimensionString,
      Privilege: Privilege,
      Arcade: Arcade,
      m_materials,
      m_selectedMaterial,
      m_wire,
      m_order,
      cancelDialog,
      m_wireTypeString,
      cancelWire,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.reduce-br {
  border-radius: 5px;
}

.text-subtitle-1 {
  line-height: normal;
  color: rgb(var(--v-theme-primary)) !important;

  :deep(.v-label) {
    opacity: 1 !important;
  }
}
</style>
