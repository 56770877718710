import { Constants } from "@/Constants";
import { MeshHelper } from "@/helpers/MeshHelper";
import Arch from "@/models/Arch";
import Logger from "@/shared/logger";
import { Arcade } from "@winnove/vue-wlib/enums";
import { saveAs } from "file-saver";
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Material,
  Matrix4,
  Mesh,
  MeshMatcapMaterial,
  MeshPhongMaterial,
  Scene as Scene3D,
  Vector3,
  Vector4,
} from "three";
import { computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import SceneControls from "./SceneControls";

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

// Base class for all meshes in the scene.
export default class SceneMesh {
  public mesh: Mesh | null = null;
  public scene: Scene3D;
  public planeIntersection = { plane: { value: new Vector4() } };
  protected _controls: SceneControls;

  constructor(scene: Scene3D, controls: SceneControls) {
    this.scene = scene;
    this._controls = controls;
  }

  public setMeshVisible(p_visible: boolean): void {
    if (this.mesh) {
      this.mesh.visible = p_visible;
    }
  }

  public toggleMeshVisible(): boolean | undefined {
    if (this.mesh) {
      this.mesh.visible = !this.mesh.visible;
      return this.mesh.visible;
    }
  }

  public isVisible(): boolean {
    return this.mesh ? this.mesh.visible : false;
  }

  public toggleWireframe(): void {
    if (this.mesh) {
      (this.mesh.material as MeshPhongMaterial).wireframe = !(
        this.mesh.material as MeshPhongMaterial
      ).wireframe;
    }
  }

  protected _disposeMesh(p_mesh?: Mesh | null): void {
    if (p_mesh) {
      p_mesh.geometry.dispose();
      if (p_mesh.material instanceof MeshMatcapMaterial) {
        p_mesh.material.map?.dispose();
      }
      (p_mesh.material as Material).dispose();
      this.scene.remove(p_mesh);
    }
  }

  public disposeMesh(): void {
    this._disposeMesh(this.mesh);
    this.mesh = null;
  }

  public disposeAll(): void {
    this.disposeMesh();
  }

  protected _rotateMesh(
    p_mesh?: Mesh,
    p_axis?: Vector3,
    p_angle?: number
  ): void {
    if (p_mesh && p_axis && p_angle) {
      p_mesh.rotateOnWorldAxis(p_axis, p_angle);
      p_mesh.updateMatrixWorld(true);
    }
  }

  public rotate(p_axis: Vector3, p_angle: number): void {
    if (this.mesh) this._rotateMesh(this.mesh, p_axis, p_angle);
  }

  public translate(p_vector: Vector3): void {
    if (this.mesh) {
      this.mesh.position.add(p_vector);
      this.mesh.updateMatrixWorld(true);
    }
  }

  public applyMatrix4(p_matrix: Matrix4): void {
    if (this.mesh) {
      this.mesh.applyMatrix4(p_matrix);
      this.mesh.updateMatrixWorld(true);
    }
  }

  private _saveMeshAsSTL(p_mesh: Mesh, p_name: string): void {
    const exporter = new STLExporter();
    const options = { binary: true };
    const buffer = exporter.parse(p_mesh, options);
    const blob = new Blob([buffer]);
    saveAs(blob, p_name);
  }

  public saveMeshAsSTL(p_name: string): void {
    if (this.mesh) {
      this._saveMeshAsSTL(this.mesh, p_name);
    } else {
      Logger.getInstance().error(
        "Impossible d'exporter ce mesh en STL car il n'existe pas."
      );
    }
  }

  // loads a STL file into the scene, return true if the file was loaded successfully
  public loadFile(p_data: ArrayBuffer, p_arch: Arch): boolean {
    try {
      const loader: STLLoader = new STLLoader();
      const geometry: BufferGeometry = loader.parse(p_data);
      const vertexCount: number = geometry.getAttribute("position").count;
      const colors = new Float32Array(vertexCount * 3);

      colors.fill(1);
      geometry.setAttribute("color", new BufferAttribute(colors, 3));
      geometry.attributes.color.needsUpdate = true;

      const material: MeshPhongMaterial = new MeshPhongMaterial({
        color: Constants.MESH_MATERIAL_COLOR,
        shininess: Constants.MESH_MATERIAL_SHININESS,
        side: DoubleSide,
        vertexColors: true,
        flatShading: true,
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
        transparent: true,
      });

      // compute the bounds tree of the geometry
      geometry.computeBoundsTree();

      const planeInterColor = Constants.MESH_PLANE_MATERIAL_COLOR;
      const pIred = (((planeInterColor >> 16) & 0xff) / 255).toString();
      const pIgreen = (((planeInterColor >> 8) & 0xff) / 255).toString();
      const pIblue = ((planeInterColor & 0xff) / 255).toString();

      material.onBeforeCompile = (shader) => {
        shader.uniforms.plane = this.planeIntersection.plane;
        shader.vertexShader = `varying vec3 vectorWorldPosition;
                ${shader.vertexShader}`.replace(
          `#include <worldpos_vertex>`,
          `vec4 worldPosition = modelMatrix * vec4( transformed, 1.0 );
                  vectorWorldPosition = worldPosition.xyz;`
        );
        shader.fragmentShader = `uniform vec4 plane;
                varying vec3 vectorWorldPosition;
                ${shader.fragmentShader}`.replace(
          `#include <dithering_fragment>`,
          `#include <dithering_fragment>
                    vec3 color = vec3(${pIred}, ${pIgreen}, ${pIblue});
                    // xyz normale du plan et w constante (distance entre 0 0 0 et le plan)
                    float linePosition = plane.w - dot(vectorWorldPosition, plane.xyz);
                    float lineWidth = fwidth(linePosition) * 2.;
                    float linePixels = smoothstep(lineWidth, 0., abs(linePosition));
                    gl_FragColor.rgb = mix(gl_FragColor.rgb, color, linePixels);
`
        );
      };

      this.mesh = new Mesh(geometry, material);
      this.mesh.name =
        p_arch.jaw === Arcade.MAXILLA
          ? Constants.MESH_MAXILLA_NAME
          : Constants.MESH_MANDIBLE_NAME;
      MeshHelper.prepareJawMesh(
        this.mesh,
        p_arch.modificationDate,
        p_arch.matrix
      );

      if (this._controls.dat) {
        try {
          // add material to dat.gui
          const existingFolder = this._controls.dat.__folders[this.mesh.name];
          if (existingFolder) {
            this._controls.dat.removeFolder(existingFolder);
          }
          const meshFolder = this._controls.dat.addFolder(this.mesh.name);
          meshFolder.add(material, "shininess", 0, 100);
        } catch (error: any) {
          Logger.getInstance().error(
            error.message,
            "Une erreur est survenue lors de l'ajout du mesh au dat.gui."
          );
        }
      }

      this.scene.add(this.mesh);

      return true;
    } catch (error: any) {
      // shouldn't happen because there's guards on the stl file uploading but just in case
      Logger.getInstance().error(
        error.message,
        "Une erreur est survenue lors du chargement du fichier STL. Veuillez vérifier que le fichier est valide et réessayer."
      );
      return false;
    }
  }

  public toggleOpacity(): void {
    if (this.mesh) {
      const opacity = (this.mesh.material as MeshPhongMaterial).opacity;
      (this.mesh.material as MeshPhongMaterial).opacity =
        opacity === Constants.MESH_OPACITY
          ? Constants.MESH_NO_OPACITY
          : Constants.MESH_OPACITY;
    }
  }
}
