<template>
  <div>
    <w-card
      elevation="5"
      class="scene-toolbar"
    >
      <w-card-text
        class="pa-1"
        v-if="editingPrescription || editingWire"
      >
        <div v-if="editingPrescription">
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/upload.svg'"
            :p_imgActive="'/img/icon/active/upload.svg'"
            :p_imgDisabled="'/img/icon/disabled/upload.svg'"
            :p_link="'/img/gifs/upload.png'"
            :p_caption="'Charger un fichier'"
            :p_disabled="
              !editingPrescription || p_rotationToolActive || p_filesUploading
            "
            :p_disabledWarning="
              !editingPrescription
                ? 'Charger un fichier est possible seulement lorsque vous êtes en train d\'éditer la prescription.'
                : 'Il n\'est pas possible de charger un fichier en mode rotation.'
            "
            @click="loadFile()"
          ></BasicTool>
          <w-divider></w-divider>
          <!-- Tourner les fichiers -->
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/rotate_jaws.svg'"
            :p_imgActive="'/img/icon/active/rotate_jaws.svg'"
            :p_imgDisabled="'/img/icon/disabled/rotate_jaws.svg'"
            :p_caption="'Tourner les fichiers de 90°'"
            :p_disabled="!editingPrescription || p_rotationToolActive"
            :p_disabledWarning="
              !editingPrescription
                ? 'Impossible de tourner les fichiers en dehors de l\'étape de modification de la prescription '
                : 'Il n\'est pas possible de tourner les fichiers en mode rotation.'
            "
            @click="rotateLingual()"
          ></BasicTool>
          <BasicTool
            v-if="hasMaxiArch"
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/turn_maxilla.svg'"
            :p_imgActive="'/img/icon/active/turn_maxilla.svg'"
            :p_imgDisabled="'/img/icon/disabled/turn_maxilla.svg'"
            :p_caption="'Retouner le maxillaire'"
            :p_disabled="!editingPrescription || p_rotationToolActive"
            :p_disabledWarning="
              !editingPrescription
                ? 'Impossible de tourner les fichiers en dehors de l\'étape de modification de la prescription '
                : 'Il n\'est pas possible de retourner le maxillaire en mode rotation.'
            "
            @click="turnMaxilla()"
          ></BasicTool>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/rotate_jaws_3D.svg'"
            :p_imgActive="'/img/icon/active/rotate_jaws_3D.svg'"
            :p_imgDisabled="'/img/icon/disabled/rotate_jaws_3D.svg'"
            :p_caption="'[r] Tourner les scans'"
            :p_disabled="!editingPrescription"
            :p_active="p_rotationToolActive"
            :p_disabledWarning="'Impossible de tourner les fichiers en dehors de l\'étape de modification de la prescription '"
            @click="toggleRotationTool()"
          ></BasicTool>
        </div>

        <!-- Insert / remove points -->
        <div v-if="editingWire">
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/insert_point.svg'"
            :p_imgActive="'/img/icon/active/insert_point.svg'"
            :p_imgDisabled="'/img/icon/disabled/insert_point.svg'"
            :p_caption="'[i] Insérer un point à droite du point sélectionné'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            @click="insertPoint()"
          ></BasicTool>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/remove_point.svg'"
            :p_imgActive="'/img/icon/active/remove_point.svg'"
            :p_imgDisabled="'/img/icon/disabled/remove_point.svg'"
            :p_caption="'[suppr] Supprimer le point sélectionné'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            @click="removePoint()"
          ></BasicTool>

          <!-- Gimbal -->
          <w-divider></w-divider>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/gimbal_antero.svg'"
            :p_imgActive="'/img/icon/active/gimbal_antero.svg'"
            :p_imgDisabled="'/img/icon/disabled/gimbal_antero.svg'"
            :p_link="'/img/gifs/gimbal_antero.png'"
            :p_caption="'[a] Bouger le point dans l\'antéro-postérieur (inter-dentaire)'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            :p_active="isGimbalAntero"
            @click="toggleGimbal(GimbalType.ANTERO_POSTERIOR)"
          ></BasicTool>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/gimbal_transversal.svg'"
            :p_imgActive="'/img/icon/active/gimbal_transversal.svg'"
            :p_imgDisabled="'/img/icon/disabled/gimbal_transversal.svg'"
            :p_link="'/img/gifs/gimbal_transversal.png'"
            :p_caption="'[t] Bouger le point en tranversal (dans le fil)'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            :p_active="isGimbalTransversal"
            @click="toggleGimbal(GimbalType.TRANSVERSAL)"
          ></BasicTool>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/gimbal_vertical.svg'"
            :p_imgActive="'/img/icon/active/gimbal_vertical.svg'"
            :p_imgDisabled="'/img/icon/disabled/gimbal_vertical.svg'"
            :p_link="'/img/gifs/gimbal_vertical.png'"
            :p_caption="'[v] Bouger le point verticalement'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            :p_active="isGimbalVertical"
            @click="toggleGimbal(GimbalType.VERTICAL)"
          ></BasicTool>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/gimbal_2D.svg'"
            :p_imgActive="'/img/icon/active/gimbal_2D.svg'"
            :p_imgDisabled="'/img/icon/disabled/gimbal_2D.svg'"
            :p_link="'/img/gifs/gimbal_2D.png'"
            :p_caption="'[p] Bouger le point dans le plan'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            :p_active="gimbalType === GimbalType.PLANE"
            @click="toggleGimbal(GimbalType.PLANE)"
          ></BasicTool>
          <BasicTool
            class="py-1"
            p_tooltipOnTheRight
            :p_imgDefault="'/img/icon/default/gimbal_3D.svg'"
            :p_imgActive="'/img/icon/active/gimbal_3D.svg'"
            :p_imgDisabled="'/img/icon/disabled/gimbal_3D.svg'"
            :p_link="'/img/gifs/gimbal_3D.png'"
            :p_caption="'[w] Bouger le point dans l\'espace 3D'"
            :p_disabled="!manualEditingWire"
            :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
            :p_active="gimbalType === GimbalType.BASE"
            @click="toggleGimbal(GimbalType.BASE)"
          ></BasicTool>

          <!-- Admin / IN OFFICE specific tools -->
          <div
            v-if="
              p_privilege === Privilege.ADMIN ||
              p_privilege === Privilege.IN_OFFICE
            "
          >
            <w-divider></w-divider>
            <BasicTool
              class="py-1"
              p_tooltipOnTheRight
              :p_imgDefault="'/img/icon/default/disable_distance_check.svg'"
              :p_imgActive="'/img/icon/active/disable_distance_check.svg'"
              :p_imgDisabled="'/img/icon/disabled/disable_distance_check.svg'"
              :p_link="'/img/gifs/disable_distance_check.png'"
              :p_caption="'Active / Désactive la vérification de distance'"
              :p_disabled="!editingWire"
              :p_disabledWarning="'Outil actif seulement lors de l\'édition d\'un fil.'"
              :p_active="!distanceCheckDisabled"
              @click="toggleDistanceCheck()"
            ></BasicTool>
          </div>
        </div>
      </w-card-text>
    </w-card>
  </div>
</template>

<script lang="ts">
import BasicTool from "@/components/shared/BasicTool.vue";
import Project from "@/models/Project";
import Logger from "@/shared/logger";
import { EditorState, GimbalType, Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "SceneToolbar",
  components: { BasicTool },
  props: {
    p_project: {
      type: Object as () => Project,
      required: false,
    },
    p_editorState: {
      type: Number as () => EditorState,
      required: true,
    },
    p_rotationToolActive: {
      type: Boolean as () => boolean,
      required: false,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_filesUploading: {
      type: Boolean as () => boolean,
      required: false,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_visible = ref(true);
    const hasMaxiArch = computed(() => {
      return (
        !!props.p_project?.archMaxilla() && !!props.p_project.archMaxilla()!.id
      );
    });

    const hasMandiArch = computed(() => {
      return (
        !!props.p_project?.archMandible() &&
        !!props.p_project.archMandible()!.id
      );
    });

    const editingPrescription = computed(() => {
      return props.p_editorState === EditorState.PRESCRIPTION_EDIT;
    });

    const manualEditingWire = computed(() => {
      return props.p_editorState === EditorState.WIRE_MANUAL_EDITING;
    });

    const editingWire = computed(() => {
      return (
        props.p_editorState === EditorState.WIRE_MANUAL_EDITING ||
        props.p_editorState === EditorState.WIRE_DRAWING
      );
    });

    const gimbalType = ref(GimbalType.NONE);
    const isGimbalAntero = ref(false);
    const isGimbalTransversal = ref(false);
    const isGimbalVertical = ref(false);
    const distanceCheckDisabled = ref(true);

    function show() {
      m_visible.value = true;
    }

    function hide() {
      m_visible.value = false;
    }

    function loadFile(): void {
      context.emit("load-file");
    }

    function rotateLingual(): void {
      context.emit("rotate-lingual");
    }

    function turnMaxilla(): void {
      if (props.p_project?.archMaxilla() === null) {
        logger.info("Aucune arcade supérieure");
        return;
      }

      if (props.p_project?.occlusion) {
        logger.info("Action impossible avec une configuration en occlusion");
        return;
      }

      context.emit("turn-maxilla");
    }

    function toggleRotationTool(): void {
      context.emit("rotation-mode");
    }

    function insertPoint(): void {
      context.emit("insert-point");
    }

    function removePoint(): void {
      context.emit("remove-point");
    }

    function toggleGimbal(p_gimbalType: GimbalType): void {
      context.emit("toggle-gimbal", p_gimbalType);
    }

    function setGimbalType(p_type: GimbalType): GimbalType {
      if (p_type === GimbalType.BASE || p_type === GimbalType.PLANE) {
        isGimbalAntero.value =
          isGimbalTransversal.value =
          isGimbalVertical.value =
            false;
      }
      gimbalType.value = p_type;
      return gimbalType.value;
    }

    function setAntero(p_value: boolean): void {
      isGimbalAntero.value = p_value;
    }

    function setTransversal(p_value: boolean): void {
      isGimbalTransversal.value = p_value;
    }

    function setVertical(p_value: boolean): void {
      isGimbalVertical.value = p_value;
    }

    function toggleDistanceCheck(): void {
      distanceCheckDisabled.value = !distanceCheckDisabled.value;
      context.emit("toggle-distance-check", distanceCheckDisabled.value);
    }

    function getWireGimbals(): GimbalType[] {
      return [
        isGimbalAntero.value && GimbalType.ANTERO_POSTERIOR,
        isGimbalTransversal.value && GimbalType.TRANSVERSAL,
        isGimbalVertical.value && GimbalType.VERTICAL,
      ]
        .filter(Boolean)
        .map((gimbalType) => gimbalType as GimbalType);
    }

    function toggleGimbalType(p_gimbalType: GimbalType): void {
      switch (p_gimbalType) {
        case GimbalType.BASE:
          setGimbalType(GimbalType.BASE);
          break;
        case GimbalType.PLANE:
          setGimbalType(GimbalType.PLANE);
          break;
        case GimbalType.ANTERO_POSTERIOR:
          setAntero(!isGimbalAntero.value);
          setGimbalType(GimbalType.ANTERO_POSTERIOR);
          break;
        case GimbalType.TRANSVERSAL:
          setTransversal(!isGimbalTransversal.value);
          setGimbalType(GimbalType.TRANSVERSAL);
          break;
        case GimbalType.VERTICAL:
          setVertical(!isGimbalVertical.value);
          setGimbalType(GimbalType.VERTICAL);
          break;
      }
    }

    return {
      m_visible,
      show,
      hide,
      editingPrescription,
      loadFile,
      rotateLingual,
      turnMaxilla,
      toggleRotationTool,
      insertPoint,
      removePoint,
      toggleGimbal,
      setGimbalType,
      setAntero,
      setTransversal,
      setVertical,
      toggleDistanceCheck,
      hasMaxiArch,
      hasMandiArch,
      manualEditingWire,
      gimbalType,
      GimbalType,
      Privilege,
      distanceCheckDisabled,
      editingWire,
      isGimbalAntero,
      isGimbalTransversal,
      isGimbalVertical,
      getWireGimbals,
      toggleGimbalType,
    };
  },
});
</script>
