<template>
  <w-alert
    v-show="p_show"
    type="warning"
    color="wOrange"
    class="ma-2"
  >
    <template #default>
      <div class="d-flex align-center">
        <w-icon
          :icon="mdiAlert"
          size="large"
        ></w-icon>
        <span class="ml-2">
          {{ p_text }}
        </span>
      </div>
    </template>
  </w-alert>
</template>

<script lang="ts">
import { mdiAlert } from "@mdi/js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Headband",
  props: {
    p_text: {
      type: String,
      required: true,
    },
    p_show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    return {
      mdiAlert,
    };
  },
});
</script>
