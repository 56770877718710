<template>
  <div>
    <w-app-bar
      class="d-flex align-center bg-primary-appBar text-secondary-appBar"
    >
      <template #prepend>
        <!-- logo -->
        <router-link to="/">
          <w-img
            data-cy="iform-logo"
            src="/img/logo/iform-blanc-clinique.png"
            width="120px"
            class="px-3"
          />
        </router-link>
      </template>

      <!-- Admin view specific -->
      <div v-if="isInAdminDashboard">
        <h2>Admin</h2>
      </div>
      <w-spacer v-if="isInAdminDashboard"></w-spacer>
      <div v-if="isInAdminDashboard">
        <w-tabs
          data-cy="admin-tabs"
          class="text-secondary-appbar"
          v-model="m_adminTab"
        >
          <w-tab
            data-cy="admin-tab-projects"
            :to="{ name: ROUTE_ADMIN_PROJECTS }"
            >Projets</w-tab
          >
          <w-tab
            data-cy="admin-tab-users"
            :to="{ name: ROUTE_ADMIN_USERS }"
            >Utilisateurs</w-tab
          >
          <w-tab
            data-cy="admin-tab-billing"
            :to="{ name: ROUTE_ADMIN_BILLING }"
            >Facturation</w-tab
          >
          <w-tab
            data-cy="admin-tab-production"
            :to="{ name: ROUTE_ADMIN_CONFIGS }"
            >Config</w-tab
          >
        </w-tabs>
      </div>

      <!-- Editor view specific -->
      <div
        v-if="isInEditor"
        class="ml-3 d-none d-md-flex"
      >
        <h3>Éditeur 3D - {{ stateString }}</h3>
      </div>

      <!-- Admin view specific in project dashboard -->
      <w-spacer v-if="isAdmin && isInProjectDashboard"></w-spacer>
      <div v-if="isAdmin && isInProjectDashboard">
        <w-tabs
          centered
          data-cy="projects-admin-tabs"
          class="text-secondary-appbar"
          v-model="m_prodAdminTab"
        >
          <w-tab
            data-cy="projects-admin-tab-projects"
            :to="{ name: ROUTE_PROJECTS }"
            >Projets</w-tab
          >
          <w-tab
            data-cy="projects-admin-tab-prod"
            :to="{ name: ROUTE_PROJECTS_PROD }"
            >Prod</w-tab
          >
          <w-tab
            data-cy="projects-admin-tab-todo"
            :to="{ name: ROUTE_PROJECTS_TODO }"
            >Todo</w-tab
          >
        </w-tabs>
      </div>

      <!-- Admin view specific in productions dashboard -->
      <div v-if="isAdmin && isInProductionDashboard">
        <h2>Production</h2>
      </div>
      <w-spacer v-if="isAdmin && isInProductionDashboard"></w-spacer>
      <div v-if="isAdmin && isInProductionDashboard">
        <w-tabs
          centered
          data-cy="projects-admin-tabs"
          class="text-secondary-appbar"
          v-model="m_productionTab"
        >
          <w-tab
            data-cy="projects-admin-tab"
            :to="{ name: ROUTE_PRODUCTIONS }"
            >Accueil</w-tab
          >
          <w-tab
            data-cy="projects-admin-tab-draw"
            :to="{ name: ROUTE_PRODUCTIONS_DRAW }"
            >Dessin</w-tab
          >
          <w-tab
            data-cy="projects-admin-tab-i3d"
            :to="{ name: ROUTE_PRODUCTIONS_I3D }"
            >Impression 3D</w-tab
          >
          <w-tab
            data-cy="projects-admin-tab-make"
            :to="{ name: ROUTE_PRODUCTIONS_TOMAKE }"
            >Fabrication</w-tab
          >
          <w-tab
            data-cy="projects-admin-tab-ship"
            :to="{ name: ROUTE_PRODUCTIONS_SHIP }"
            >Expédition</w-tab
          >
        </w-tabs>
      </div>

      <!-- Admin view specific in account manager dashboard -->
      <div v-if="isAdmin && isInAccountManagerDashboard">
        <h2>Account Manager</h2>
      </div>
      <w-spacer v-if="isAdmin && isInAccountManagerDashboard"></w-spacer>
      <div v-if="isAdmin && isInAccountManagerDashboard">
        <w-tabs
          centered
          data-cy="account-manager-admin-tabs"
          class="text-secondary-appbar"
          v-model="m_accountManagerTab"
        >
          <w-tab
            data-cy="account-manager-admin-tab-tasks"
            :to="{ name: ROUTE_ACCOUNT_MANAGER_TASKS }"
            >Tâches</w-tab
          >
          <w-tab
            data-cy="account-manager-admin-tab-validation"
            :to="{ name: ROUTE_ACCOUNT_MANAGER_VALIDATION }"
            >En Validation</w-tab
          >
          <w-tab
            data-cy="account-manager-admin-tab-delivery"
            :to="{ name: ROUTE_ACCOUNT_MANAGER_DELIVERY }"
            >En Livraison</w-tab
          >
          <w-tab
            data-cy="account-manager-admin-tab-delivery"
            :to="{ name: ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS }"
            >Bloqués</w-tab
          >
        </w-tabs>
      </div>

      <!-- Common to all views -->
      <TutorialOverlay
        :p_editorState="p_editorState"
        ref="tutorialOverlay"
      >
      </TutorialOverlay>
      <w-spacer></w-spacer>

      <!-- new project button -->
      <div
        v-if="!isInEditor && !isInAdminDashboard && !isInProductionDashboard"
        class="d-none d-sm-flex flex-row"
      >
        <w-btn
          data-cy="new-project-btn"
          class="pl-8 pr-6 mx-4"
          :variant="p_route === ROUTE_PROJECTS ? 'elevated' : 'tonal'"
          @click="$emit('new-project')"
          :iconLeft="mdiPlus"
          color="secondary-appBar"
        >
          Nouveau projet
        </w-btn>
        <w-btn
          v-if="m_showDemoBtn"
          data-cy="new-project-btn"
          class="pl-8 pr-6 mx-4"
          :variant="p_route === ROUTE_PROJECTS ? 'elevated' : 'tonal'"
          @click="newProjectDemo"
          :iconLeft="mdiPlus"
          color="secondary-appBar"
        >
          Nouveau projet (Démo)
        </w-btn>
      </div>

      <!-- Tutorial button -->
      <BasicTool
        v-if="getTutorialURL() != ''"
        class="px-3 d-none d-sm-flex"
        :p_imgDefault="'/img/icon/default/tuto.svg'"
        :p_imgActive="'/img/icon/active/tuto.svg'"
        :p_imgDisabled="'/img/icon/disabled/tuto.svg'"
        :p_caption="getTutorialString()"
        @click="showTutorial(getTutorialURL())"
      ></BasicTool>

      <!-- user menu activator -->
      <div class="d-flex">
        <w-btn
          data-cy="user-menu-btn"
          id="user-menu-btn"
          variant="text"
          rounded="false"
          height="65"
          color="secondary-appBar"
        >
          Dr. {{ m_doctor }}
          <w-avatar
            size="40"
            class="ml-2"
            :rounded="false"
          >
            <w-icon
              :icon="mdiMenu"
              color="secondary-appBar"
              size="x-large"
            ></w-icon>
          </w-avatar>

          <!-- User menu -->
          <w-menu
            offset-y
            activator="parent"
            v-model="m_userMenu"
            transition="slide-y-transition"
            :close-on-click="true"
          >
            <w-list
              class="ma-0 pa-0"
              tile
              elevation="4"
            >
              <w-list-item
                v-if="isAdmin"
                data-cy="admin-menu"
                :to="{ name: ROUTE_ADMIN }"
                :prepend-icon="mdiCrownCircle"
                title="Administration"
              >
              </w-list-item>
              <w-list-item
                :to="{ name: ROUTE_PROJECTS }"
                :prepend-icon="mdiViewDashboard"
                title="Dashboard Projets"
              ></w-list-item>
              <w-list-item
                v-if="isAdmin"
                data-cy="dashboard-prod-btn"
                :to="{ name: ROUTE_PRODUCTIONS }"
                :prepend-icon="mdiRobotIndustrial"
                title="Dashboard Production"
              >
              </w-list-item>
              <w-list-item
                v-if="isAdmin"
                :to="{ name: ROUTE_ACCOUNT_MANAGER_TASKS }"
                :prepend-icon="mdiAccountCreditCard"
                title="Dashboard Account Manager"
              >
              </w-list-item>
              <w-list-item
                :to="{ name: ROUTE_ACCOUNT }"
                :prepend-icon="mdiAccount"
                title="Mon profil"
              >
              </w-list-item>
              <w-list-item
                v-if="showBiling"
                :to="{ name: ROUTE_BILLING }"
                :prepend-icon="mdiCashClock"
                title="Ma consommation"
              >
              </w-list-item>
              <w-list-item
                :to="{ name: ROUTE_PATIENTS }"
                :prepend-icon="mdiContacts"
                title="Mes patients"
              >
              </w-list-item>
              <w-list-item
                link
                :prepend-icon="mdiHelp"
                title="Aide"
                @click="$refs.helpDialog.show()"
              >
              </w-list-item>

              <w-list-item
                data-cy="logout-btn"
                @click="logout()"
                :prepend-icon="mdiLogoutVariant"
                title="Déconnexion"
              >
              </w-list-item>
              <w-divider color="wWhite"></w-divider>
              <w-list-item density="compact">
                <span style="font-size: smaller">iForm V{{ version }}</span>
              </w-list-item>
            </w-list>
          </w-menu>
        </w-btn>
      </div>
    </w-app-bar>
    <w-progress-linear
      :active="m_showLoader"
      indeterminate
      absolute
      style="top: 60px; z-index: 9999"
      color="secondary-appBar"
    ></w-progress-linear>
    <HelpDialog ref="helpDialog"></HelpDialog>
    <Headband
      v-if="!isInEditor && !isInProductionDashboard"
      :p_show="headbandShow"
      :p_text="headbandText"
    />
  </div>
</template>

<script lang="ts">
import { version } from "@/../package.json";
import Headband from "@/components/shared/Headband.vue";
import TutorialOverlay from "@/components/shared/TutorialOverlay.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import ProjectHelper from "@/helpers/ProjectHelper";
import Config from "@/models/Config";
import router, {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_MANAGER,
  ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS,
  ROUTE_ACCOUNT_MANAGER_DELIVERY,
  ROUTE_ACCOUNT_MANAGER_TASKS,
  ROUTE_ACCOUNT_MANAGER_VALIDATION,
  ROUTE_ADMIN,
  ROUTE_ADMIN_BILLING,
  ROUTE_ADMIN_CONFIGS,
  ROUTE_ADMIN_PROJECTS,
  ROUTE_ADMIN_USERS,
  ROUTE_BILLING,
  ROUTE_DASHBOARD,
  ROUTE_EDITOR,
  ROUTE_LOGOUT,
  ROUTE_PATIENTS,
  ROUTE_PRODUCTIONS,
  ROUTE_PRODUCTIONS_DRAW,
  ROUTE_PRODUCTIONS_I3D,
  ROUTE_PRODUCTIONS_SHIP,
  ROUTE_PRODUCTIONS_TOMAKE,
  ROUTE_PROJECTS,
  ROUTE_PROJECTS_PROD,
  ROUTE_PROJECTS_TODO,
} from "@/router";
import {
  mdiAccount,
  mdiAccountCreditCard,
  mdiCashClock,
  mdiContacts,
  mdiCrownCircle,
  mdiHelp,
  mdiLogoutVariant,
  mdiMenu,
  mdiPlus,
  mdiRobotIndustrial,
  mdiViewDashboard,
} from "@mdi/js";
import {
  EditorState,
  EditorStateString,
  Privilege,
  Role,
  TutorialIndex,
  TutorialStrings,
  TutorialURLs,
} from "@winnove/vue-wlib/enums";
import mitt from "mitt";
import { computed, defineComponent, onMounted, ref } from "vue";
import HelpDialog from "../main/dialogs/HelpDialog.vue";

export type LoaderBusData = {
  show: void;
  hide: void;
};
export const LoaderBus = mitt<LoaderBusData>();

export default defineComponent({
  name: "AppBar",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_route: {
      type: String,
      required: true,
    },
    p_editorState: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {
    const m_doctor = ref("");
    const m_userMenu = ref(false);
    const m_showDemoBtn = ref(false);

    const headbandShow = computed((): boolean => {
      return Boolean(Number(Config.getValue("HEADBAND_SHOW")));
    });

    const headbandText = computed((): string => {
      return Config.getValue("HEADBAND_TEXT");
    });

    const setDoctorName = (): void => {
      m_doctor.value = AuthHelper.getLoggedUser().lastName.toUpperCase();
      m_showDemoBtn.value = AuthHelper.getLoggedUser().role === Role.DEMO;
    };

    const isAdmin = computed((): boolean => {
      return props.p_privilege == Privilege.ADMIN;
    });
    const showBiling = computed((): boolean => {
      return (
        props.p_privilege == Privilege.ADMIN ||
        props.p_privilege == Privilege.FREEMIUM ||
        props.p_privilege == Privilege.PREMIUM
      );
    });
    const isInAdminDashboard = computed((): boolean => {
      return props.p_route.startsWith(ROUTE_ADMIN);
    });
    const isInProjectDashboard = computed((): boolean => {
      return (
        props.p_route.startsWith(ROUTE_PROJECTS) ||
        props.p_route.startsWith(ROUTE_DASHBOARD)
      );
    });
    const isInProductionDashboard = computed((): boolean => {
      return props.p_route.startsWith(ROUTE_PRODUCTIONS);
    });
    const isInAccountManagerDashboard = computed((): boolean => {
      return props.p_route.startsWith(ROUTE_ACCOUNT_MANAGER);
    });
    const isInEditor = computed((): boolean => {
      return props.p_route.startsWith(ROUTE_EDITOR);
    });
    const stateString = computed((): string => {
      return EditorStateString[props.p_editorState ?? 0];
    });
    const tutorialOverlay = ref<InstanceType<typeof TutorialOverlay> | null>(
      null
    );
    const m_showLoader = ref(false);

    const m_adminTab = ref(0);
    const m_prodAdminTab = ref(0);
    const m_productionTab = ref(0);
    const m_accountManagerTab = ref(0);

    const logout = (): void => {
      router.push({ name: ROUTE_LOGOUT });
    };
    const getTutorialURL = (): string => {
      if (isInEditor.value) {
        switch (props.p_editorState) {
          case EditorState.PRESCRIPTION_EDIT:
            return TutorialURLs[TutorialIndex.DASHBOARD];
          case EditorState.WIRE_MANUAL_EDITING:
            return TutorialURLs[TutorialIndex.EDITING];
          case EditorState.WIRE_DRAWING:
            return TutorialURLs[TutorialIndex.DRAWING];
          default:
            return "";
        }
      } else if (props.p_route === ROUTE_PROJECTS) {
        return TutorialURLs[TutorialIndex.DASHBOARD];
      }
      return "";
    };
    const getTutorialString = (): string => {
      if (isInEditor.value) {
        switch (props.p_editorState) {
          case EditorState.PRESCRIPTION_EDIT:
            return TutorialStrings[TutorialIndex.DASHBOARD];
          case EditorState.WIRE_MANUAL_EDITING:
            return TutorialStrings[TutorialIndex.EDITING];
          case EditorState.WIRE_DRAWING:
            return TutorialStrings[TutorialIndex.DRAWING];
          default:
            return "";
        }
      }
      return TutorialStrings[TutorialIndex.DASHBOARD];
    };
    let loaderTimeout: NodeJS.Timeout;
    let loaderEventCount = 0;
    onMounted(() => {
      setDoctorName();
      LoaderBus.on("show", () => {
        m_showLoader.value = true;
        loaderEventCount++;
        clearTimeout(loaderTimeout);
      });
      LoaderBus.on("hide", () => {
        if (loaderEventCount > 0) loaderEventCount--;
        // Delay to avoid flickering
        if (loaderEventCount === 0) {
          loaderTimeout = setTimeout(() => {
            m_showLoader.value = false;
          }, 800);
        }
      });
    });
    function showTutorial(p_link: string): void {
      tutorialOverlay.value?.show(p_link);
    }

    async function newProjectDemo(): Promise<void> {
      const projectRef = await ProjectHelper.createDemoProject();
      router.push({
        name: ROUTE_EDITOR,
        params: { reference: projectRef },
      });
    }
    return {
      isAdmin,
      showBiling,
      isInAdminDashboard,
      isInProjectDashboard,
      isInProductionDashboard,
      isInEditor,
      ROUTE_ACCOUNT,
      ROUTE_BILLING,
      ROUTE_PATIENTS,
      ROUTE_ADMIN,
      ROUTE_PROJECTS,
      ROUTE_PRODUCTIONS,
      ROUTE_ADMIN_CONFIGS,
      ROUTE_ADMIN_PROJECTS,
      ROUTE_ADMIN_BILLING,
      ROUTE_ADMIN_USERS,
      ROUTE_PROJECTS_PROD,
      ROUTE_PROJECTS_TODO,
      ROUTE_PRODUCTIONS_DRAW,
      ROUTE_PRODUCTIONS_I3D,
      ROUTE_PRODUCTIONS_TOMAKE,
      ROUTE_PRODUCTIONS_SHIP,
      ROUTE_ACCOUNT_MANAGER,
      ROUTE_ACCOUNT_MANAGER_VALIDATION,
      ROUTE_ACCOUNT_MANAGER_DELIVERY,
      ROUTE_ACCOUNT_MANAGER_BLOCK_PROJECTS,
      ROUTE_ACCOUNT_MANAGER_TASKS,
      m_doctor,
      m_showDemoBtn,
      newProjectDemo,
      setDoctorName,
      logout,
      version,
      stateString,
      tutorialOverlay,
      getTutorialURL,
      getTutorialString,
      m_userMenu,
      m_showLoader,
      showTutorial,
      mdiPlus,
      mdiCrownCircle,
      mdiViewDashboard,
      mdiAccount,
      mdiCashClock,
      mdiContacts,
      mdiHelp,
      mdiLogoutVariant,
      m_adminTab,
      m_prodAdminTab,
      m_productionTab,
      mdiAccountCreditCard,
      mdiRobotIndustrial,
      mdiMenu,
      isInAccountManagerDashboard,
      m_accountManagerTab,
      headbandShow,
      headbandText,
    };
  },
  components: { HelpDialog, Headband },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 20px 2px #d6e2ef !important;
}
</style>
